import {Company} from './interfaces';

export type CompanyPartnership = {
    id: string
    name: string // needed for searchbar
    buyerId: string
    sellerId: string
    buyer: Company | undefined
    seller: Company | undefined
    status: CompanyPartnershipStatus
    creationDate: string
}

export enum CompanyPartnershipStatus {
    requested = 'REQUESTED',
    active = 'ACTIVE'
}