import {useAuth} from './AuthProvider';
import {Navigate, Outlet} from 'react-router-dom';
import React from 'react';
import {LoadingScreen} from '../../components/LoadingScreen';
import {APP_ROUTES} from '../../constants';


export const ProtectedRoutes = () => {
	const auth = useAuth();

	if (auth.loggedIn === null) {
		return <LoadingScreen/>;
	}

	if (!auth.loggedIn) {
		console.log('Not logged in redirect to /login');
		return <Navigate to={APP_ROUTES.login} replace />;
	}

	return <Outlet/>;
};