import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ImageGallery from './components/ImageGallery';
import uuid from 'react-uuid';
import FilesAndDownloads from './components/FilesAndDownloads';
import IconButton, {IconType} from '../../../components/button/IconButton';
import DeleteProductModal from '../productTable/components/ProductDeleteModal';
import {
	APP_ROUTES,
	Constants,
	endPointGetImage,
	endPointProduct,
	routeEditProductWithoutVariants
} from '../../../constants';
import ProductPropertiesTable from './components/ProductPropertiesTable';
import FormatDate from '../../../utils/date';
import {Product, Variant} from '../../../interfaces';
import Necessity = Constants.Necessity;


export default function Details() {
	const {productId, variantId} = useParams<string>();
	const navigate = useNavigate();

	const [variant, setVariant] = useState<Variant>();
	const [product, setProduct] = useState<Product>();

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

	const onOpenDelteModal = () => setIsDeleteModalVisible(true);
	const onCloseDelteModal = () => setIsDeleteModalVisible(false);

	useEffect(() => {
		if (productId){
			loadProduct(productId).then();
		}
	}, []);

	const loadProduct = async (productId:string) => {
		axios.get(endPointProduct(productId), {
			params: {fromCompany: true}
		})
			.then((response) => {
				setProduct(response.data);
				setVariant(response.data.variants.find((elem: { id: string | undefined; }) => elem.id === variantId));
			})
			.catch(errors => {
				console.log(errors);
			});
	};

	return(
		<>
			{variant !== undefined &&
				<div className="flex flex-col gap-20 px-10 py-3">

					<div className="flex w-full gap-20">
						<ImageGallery
							wrapperClasses="w-1/5 self-start"
							imageIds={ (variant.imageIds && variant.planIds) ? variant.imageIds.concat(variant.planIds) : variant.imageIds}/>

						<div className="flex flex-col flex-1">
							<div >
								<img
									src={variant.manufacturer?.imageId?endPointGetImage(variant.manufacturer?.imageId):''}
									className="w-40 h-10 object-scale-down"
									alt={'no image available'}
									id={'img' + uuid()}/>

								<div className="flex justify-between text-2xl font-semibold ">
									{variant.name}&nbsp;{variant.properties.find(item=>item.necessity === Necessity.Variation)?.value}

									{product && product.variants?.length === 1 &&
									<div className='flex gap-2'>
										<IconButton
											onClick={() => {navigate(routeEditProductWithoutVariants(productId??''));}}
											icon={IconType.PENCIL}/>

										<IconButton
											onClick={onOpenDelteModal}
											icon={IconType.TRASH}/>

										<DeleteProductModal
											isVisible={isDeleteModalVisible}
											onClose={onCloseDelteModal}
											onSuccess={()=>navigate(APP_ROUTES.products)}
											variant={variant}/>
									</div>
									}
								</div>
							</div>

							<div className="flex pt-10 gap-10">
								<div className="flex flex-col gap-1">

									<div className="flex ">
										<div  className="text-lg font-semibold self-center">
											Artikelnummer:
										</div>
										<div className="self-center pt-0.5">
											&nbsp;&nbsp;{variant.articleNumber}
										</div>
									</div>

									<div className="flex">
										<div  className="text-lg font-semibold self-center">
											Herstellernummer:
										</div>
										<div className="self-center pt-0.5">
											&nbsp;&nbsp;{variant.manufacturerArticleNumber}
										</div>
									</div>


									<div className="flex gap-3 items-center text-lg font-semibold">
										<div className="pb-0.5">
											Status:
										</div>
										{variant.active?
											<span className="inline-flex rounded-full bg-green-200 px-2 font-semibold text-sm leading-5 text-black">
												Aktiv
											</span>:
											<span className="inline-flex rounded-full bg-yellow-300 px-2 font-semibold leading-5 text-black">
												Inaktiv
											</span>
										}
									</div>

									<div className="flex">
										<div  className="text-lg font-semibold self-center">
											Anzahl verfügbar:
										</div>
										<div className="self-center pt-0.5">
											&nbsp;&nbsp;{variant.amountAvailable}
										</div>
									</div>

									<div className="flex">
										<div  className="text-lg font-semibold self-center">
											Kategorie:
										</div>
										<div className="self-center pt-0.5">
											&nbsp;&nbsp;{product?.category?.name ?? '-'}
										</div>
									</div>

									<div className="flex text-xl pt-7">
										<div className="flex flex-col items-start">

											<div className="flex">
												<div  className="text-xl font-semibold self-center">
													Preis:
												</div>
												<div className="self-center pt-0.5">
													&nbsp;&nbsp;{variant.price? ((variant.price/100) + ' €').replace('.',',') :''}
												</div>
											</div>

											<div className="flex flex-col text-red-500">

												<div className="flex">
													<div  className="text-xl font-semibold self-center">
														Reduziert:
													</div>
													<div className="self-center pt-0.5">
														&nbsp;&nbsp;{variant.effectiveSale?.price ? (variant.effectiveSale.price / 100 + ' €').replace('.', ',') : '-'}
													</div>
												</div>

												<div className="flex">
													<div  className="text-xl font-semibold self-center">
														Rabatt:
													</div>
													<div className="self-center pt-0.5">
														&nbsp;&nbsp;{variant.effectiveSale ? (variant.effectiveSale.percentage / 100 + ' %').replace('.', ',') : '-'}
													</div>
												</div>

												<div className="flex">
													<div  className="text-xl font-semibold self-center">
														Rabatt:
													</div>
													<div className="self-center pt-0.5">
														&nbsp;&nbsp;{variant.effectiveSale?.endDate ? FormatDate(variant.effectiveSale.endDate) : '-'}
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>

								<div className=" self-start">
									<div className="flex flex-col w-full">
										<div className="text-lg font-semibold">Kurzbeschreibung</div>
										<div>
											{variant.shortDescription}
										</div>
									</div>

									<div className="flex flex-col w-full">
										<div className="text-lg font-semibold">Ausführliche Beschreibung</div>
										<div className="break-normal">
											{variant.longDescription}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div className="flex gap-10">
						<div className="w-5/12">
							<div className="flex justify-center text-2xl font-semibold">
							Technische Daten
							</div>
							<ProductPropertiesTable
								properties={variant.properties}
							/>
						</div>


						<FilesAndDownloads
							wrapperClasses={'w-1/3'}
							planIds={variant.planIds}
							dataSheetIds={variant.dataSheetIds}
							dxfFileIds={variant.dxfFileIds}
							stpFileIds={variant.stpFileIds}/>
					</div>
				</div>
			}
		</>
	);
}
