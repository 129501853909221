import React from 'react';
import {
	ArrowDownTrayIcon,
	ArrowLeftIcon,
	ArrowUpTrayIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ListBulletIcon,
	PencilIcon,
	PlusCircleIcon,
	SwatchIcon,
	XMarkIcon,
} from '@heroicons/react/20/solid';
import {ButtonType} from './Button';
import classNames from 'classnames';
import {TrashIcon} from '@heroicons/react/24/solid';
import {HiOutlineCheckCircle, HiOutlinePlusCircle} from 'react-icons/hi';

export enum IconType {
	WITHOUT,
	CHECK,
	PLUS,
	TRASH,
	DOWNLOAD,
	UPLOAD,
	PENCIL,
	BURGER,
	ARROW_LEFT,
	ARROW_RIGHT,
	CHEVRON_LEFT,
	CHEVRON_RIGHT,
	SWATCH,
	ADD_FRIEND,
	X_MARK
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	type?: ButtonType;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	icon: IconType;
	title?: string;
	disabled?: boolean;
	focus?: boolean;
	wrapperClasses?: string;
}

const IconButton = ({
	type = ButtonType.BUTTON,
	onClick,
	icon,
	disabled,
	focus = true,
	title,
	wrapperClasses,
}: Props) => {
	return (
		<div className={classNames(wrapperClasses)}>
			<button
				type={type}
				onClick={onClick}
				disabled={disabled}
				title={title}
				className={
					'inline-flex items-center rounded-md border border-gray-300 bg-white px-1 py-1 ' +
					'text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 ' +
					(focus &&
						'focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2')
				}
			>
				{icon === IconType.CHECK ? (
					<HiOutlineCheckCircle className="h-5 w-5 text-gray-500" aria-hidden="true"/>
				) : icon === IconType.PENCIL ? (
					<PencilIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
				) : icon === IconType.DOWNLOAD ? (
					<ArrowDownTrayIcon
						className="h-5 w-5 text-gray-500"
						aria-hidden="true"/>
				) : icon === IconType.TRASH ? (
					<TrashIcon className="h-5 w-5" aria-hidden="true"/>
				) : icon === IconType.UPLOAD ? (
					<ArrowUpTrayIcon
						className="h-5 w-5 text-gray-500"
						aria-hidden="true"/>
				) : icon === IconType.PLUS ? (
					<PlusCircleIcon
						className="h-5 w-5 text-gray-500"
						aria-hidden="true"/>
				) : icon === IconType.BURGER ? (
					<ListBulletIcon
						className="h-5 w-5 text-gray-500"
						aria-hidden="true"/>
				) : icon === IconType.ARROW_LEFT ? (
					<ArrowLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
				) : icon === IconType.CHEVRON_LEFT ? (
					<ChevronLeftIcon
						className="h-5 w-5 text-gray-500"
						aria-hidden="true"/>
				) : icon === IconType.CHEVRON_RIGHT ? (
					<ChevronRightIcon
						className="h-5 w-5 text-gray-500"
						aria-hidden="true"/>
				) : icon === IconType.SWATCH ? (
					<SwatchIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
				) : icon === IconType.ADD_FRIEND ? (
					<HiOutlinePlusCircle
						className="h-5 w-5 text-gray-500"
						aria-hidden="true"/>
				) : icon === IconType.X_MARK ? (
					<XMarkIcon
						className="h-5 w-5 text-gray-500"
						aria-hidden="true"/>)
					: null}
			</button>
		</div>
	);
};

export default IconButton;
