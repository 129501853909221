import React, {useState} from 'react';
import {Disclosure} from '@headlessui/react';
import {useNavigate} from 'react-router-dom';
import {
	ArrowDownOnSquareIcon,
	ArrowDownOnSquareStackIcon,
	ClipboardDocumentCheckIcon,
	HomeIcon,
	ShoppingBagIcon,
	UsersIcon
} from '@heroicons/react/24/outline';
import {IoLogoEuro} from 'react-icons/io5';
import {InformationCircleIcon} from '@heroicons/react/20/solid';
import {APP_ROUTES} from '../../../constants';
import {FaUserFriends} from 'react-icons/fa';


function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}


const SideBar = () =>{
	const navigate = useNavigate();

	const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);


	const navigation = [
		{ name: 'Kaufen', icon: ShoppingBagIcon, current:true, index:0 ,
			children: [
				{
					name: 'Bestellungen',
					navRoute: APP_ROUTES.orders,
					icon: ClipboardDocumentCheckIcon,
					current: true,
					index: 0
				},
			]
		},
		{ name: 'Verkaufen', icon: IoLogoEuro, current:false, index:1,
			children: [
				{
					name: 'Produkte',
					navRoute: APP_ROUTES.products,
					icon: ClipboardDocumentCheckIcon,
					current: true,
					index: 0
				},
				{
					name: 'Rabatt auf Produkte erstellen',
					navRoute: APP_ROUTES.createVariantSale,
					icon:ArrowDownOnSquareIcon ,
					current:true,
					index: 0
				},
				{
					name: 'Rabatt auf Produkte löschen',
					navRoute: APP_ROUTES.manageVariantSale,
					icon: ArrowDownOnSquareIcon,
					current: true,
					index: 0
				},
				{
					name: 'Sonderkonditionen auf Produkte erstellen',
					navRoute: APP_ROUTES.createVariantSpecialCondition,
					icon: ArrowDownOnSquareIcon,
					current: true,
					index: 0
				},
				{
					name: 'Sonderkonditionen auf Produkte löschen',
					navRoute: APP_ROUTES.manageVariantSpecialCondition,
					icon: ArrowDownOnSquareIcon,
					current: true,
					index: 0
				},
				{
					name: 'Rabatt auf Kategorien erstellen',
					navRoute: APP_ROUTES.createCategorySale,
					icon: ArrowDownOnSquareStackIcon,
					current:true,
					index:0
				},
				{
					name: 'Rabatte auf Kategorien',
					navRoute: APP_ROUTES.manageCategorySale,
					icon: ArrowDownOnSquareStackIcon,
					current: true,
					index: 0
				},
			]
		},
		{ name: 'Unternehmen', icon: HomeIcon, current:false, index:2,
			children: [
				{
					name: 'Informationen',
					navRoute: APP_ROUTES.companyInformation,
					icon: InformationCircleIcon,
					current: true,
					index: 0
				},
				{name: 'Benutzer', navRoute: APP_ROUTES.userManagement, icon: UsersIcon, current: false, index: 1},
			]
		},
		{
			name: 'Partnerunternehmen', icon: FaUserFriends, current: false, index: 4,
			children: [
				{
					name: 'Anfrage stellen',
					navRoute: APP_ROUTES.partnershipSelection,
					icon: ClipboardDocumentCheckIcon,
					current: true,
					index: 0
				},
				{
					name: 'Eingehenede Anfragen',
					navRoute: APP_ROUTES.partnershipRequests,
					icon: ClipboardDocumentCheckIcon,
					current: false,
					index: 1
				},
				{
					name: 'Verkäufer',
					navRoute: APP_ROUTES.partnershipsAsBuyer,
					icon: ClipboardDocumentCheckIcon,
					current: false,
					index: 2
				},
				{
					name: 'Käufer',
					navRoute: APP_ROUTES.partnershipsAsSeller,
					icon: ClipboardDocumentCheckIcon,
					current: false,
					index: 3
				}
			]
		},
	];

	return (
		<div className="flex flex-col w-52 border-r border-gray-200 bg-white pt-5 pb-4">
			<div className="flex items-center px-4">
				<img
					className="h-8 w-auto"
					src={`${process.env.PUBLIC_URL}/inopartLogo.jpeg`}
					alt="Inopart"
				/>
			</div>
			<div className="mt-5 flex flex-grow flex-col">
				<nav className="flex-1 space-y-1 bg-white px-2" aria-label="Sidebar">
					{navigation.map((item) =>
						!item.children ? (
							<div key={item.name}>
								<a
									className={classNames(
										item.index == selectedItemIndex
											? 'bg-gray-100 text-gray-900'
											: 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
										'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
									)}
								>
									<item.icon
										className={classNames(
											item.index == selectedItemIndex  ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
											'mr-3 flex-shrink-0 h-6 w-6'
										)}
										aria-hidden="true"
									/>
									{item.name}
								</a>
							</div>
						) : (
							<Disclosure as="div" key={item.name} defaultOpen={true} className="space-y-1">
								{({ open }) => (
									<>
										<Disclosure.Button
											className='bg-white cursor-default text-gray-600 hover:bg-gray-50 hover:text-gray-900 group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md'
										>
											<item.icon
												className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
												aria-hidden="true"
											/>
											<span className="flex-1">{item.name}</span>
										</Disclosure.Button>
										<Disclosure.Panel className="space-y-1" static={true}>
											{item.children.map((subItem) => (
												<Disclosure.Button
													key={subItem.name}
													as="a"
													className="group flex w-full items-center cursor-pointer rounded-md py-2 pl-6 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
													onClick={()=>{
														setSelectedItemIndex(item.index);
														navigate(subItem.navRoute);
													}}
												>
													<subItem.icon
														className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
													/>
													{subItem.name}
												</Disclosure.Button>
											))}
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						)
					)}
				</nav>
			</div>
		</div>
	);
};

export default SideBar;
