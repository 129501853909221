import React, {
	ChangeEvent,
	DetailedHTMLProps,
	FC,
	InputHTMLAttributes,
	KeyboardEventHandler,
	useEffect,
	useState
} from 'react';
import classNames from 'classnames';
import {Simulate} from 'react-dom/test-utils';
import input = Simulate.input;

export enum InputFieldType{
	TEXT = 'text',
	PASSWORD = 'password',
	NUMBER = 'number'
}

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
	title: string;
	wrapperClasses?: string;
}

export default function InputField (props: Props) {
	return (
		<div className={
			classNames('flex flex-col', props.wrapperClasses)
		}>
			<label className="block text-sm font-medium text-gray-700"> {props.title} </label>
			<input {...props}
				className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
			placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"/>
		</div>
	);
}