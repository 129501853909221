import React, {useState} from 'react';
import classNames from 'classnames';
import Button from '../../../../../components/button/Button';
import DeleteModal from '../../../../../components/modal/DeleteModal';
import {Constants} from '../../../../../constants';
import DataTable, {TableColumn} from 'react-data-table-component';
import IconButton, {IconType} from '../../../../../components/button/IconButton';
import {VariantChangeModal} from './components/VariantChangeModal';
import EditPropertiesTable from './components/EditPropertiesTable';
import {Property, VariantBuilder, VariantBuilderState, VariantChangeble} from '../../../../../interfaces';
import {VariantBuilderMap} from '../../../../../utils';
import FormatDate from '../../../../../utils/date';
import calcSalePrice from '../../../../../utils/salePriceCalc';
import Necessity = Constants.Necessity;


interface Props {
	variantBuilderMap: VariantBuilderMap
	setVariantBuilderMap: React.Dispatch<React.SetStateAction<VariantBuilderMap>>

	properties: Property[]
	setProperties: React.Dispatch<React.SetStateAction<Property[]>>

	categoryId:string

	errorMessage: string
	setErrorMessage: React.Dispatch<React.SetStateAction<string>>

	isEditPropertiesTableVisible: boolean
	setIsEditPropertiesTableVisible: React.Dispatch<React.SetStateAction<boolean>>

	isSalePossible: boolean

	wrapperClasses?:string
}

export default function EditVariantsTab(
	{
		variantBuilderMap, setVariantBuilderMap,
		properties, setProperties,
		errorMessage, setErrorMessage,
		categoryId,
		isEditPropertiesTableVisible, setIsEditPropertiesTableVisible,
		isSalePossible,
		wrapperClasses
	} : Props) {

	const [idToEdit, setIdToEdit] = useState<string | undefined>();
	const [idToDelete, setIdToDelete] = useState<string | undefined>();

	const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
	const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);


	const onOpenVariantModal = () => setIsCreateModalVisible(true);
	const onCloseVariantModal = () => setIsCreateModalVisible(false);


	const onOpenEditModal = (tableIndex: string) => {
		setIdToEdit(tableIndex);
		setIsEditModalVisible(true);
	};


	const onCloseEditModal = () => {
		setIdToEdit(undefined);
		setIsEditModalVisible(false);
	};


	const onSuccessEditModal = (change: VariantChangeble) => {

		if (idToEdit && variantBuilderMap.map.has(idToEdit)) {
			variantBuilderMap.map.get(idToEdit)?.setChange(change);
			setVariantBuilderMap(variantBuilderMap);
		}
		setIdToEdit(undefined);
		setIsEditModalVisible(false);
	};


	const onOpenDelteModal = (indexToDelete: string) => {
		setIsDeleteModalVisible(true);
		setIdToDelete(indexToDelete);
	};


	const onCloseDeleteModal = () => {
		setIsDeleteModalVisible(false);
		setIdToDelete(undefined);
	};


	const onSuccessDeleteModal = () => {
		if (idToDelete && variantBuilderMap.map.has(idToDelete)) {
			variantBuilderMap.map.get(idToDelete)?.setState(VariantBuilderState.delete);
			setVariantBuilderMap(variantBuilderMap);
		}
		setIsDeleteModalVisible(false);
		setIdToDelete(undefined);
	};


	const onSuccessEditPropertiesTable = (newProperties: Property[]) => {
		variantBuilderMap.map.forEach((builder, key: string) => {
			if (builder.getState() !== VariantBuilderState.delete) {
				builder.adjustProperties(newProperties);
			}
		});
		setVariantBuilderMap(variantBuilderMap);
		setProperties(newProperties);
		setIsEditPropertiesTableVisible(false);
	};


	const onAddNewVariant = (createNewVariant: VariantChangeble) => {
		const newVariantBuilder: VariantBuilder = new VariantBuilder(VariantBuilderState.create);

		newVariantBuilder.setChange(createNewVariant);

		variantBuilderMap.map.set(newVariantBuilder.getId(), newVariantBuilder);
		setVariantBuilderMap(variantBuilderMap);

		setErrorMessage('');
	};


	const columns: TableColumn<VariantBuilder>[] = [
		{
			name: 'Variante / ' + properties.find((elem)=>elem.necessity===Necessity.Variation)?.name
							+ (properties.find((elem)=>elem.necessity===Necessity.Variation)?.unit
								? ' (' + properties.find((elem)=>elem.necessity===Necessity.Variation)?.unit + ')'
								:''),
			cell: (row, rowIndex, column) => (
				<div>
					{row?.getDisplayed().properties.find(elem => elem.filterId === properties
						.find((elem)=>elem.necessity === Necessity.Variation)?.filterId)?.value
					}
				</div>
			),
			center: true,
			width: '200px',
		},
		{
			name: 'Artikelnummer',
			cell: (row, rowIndex, column) => (
				<div>{row.getDisplayed().manufacturerArticleNumber}</div>
			),
			center: true,
			width: '200px',
		},
		{
			name: 'Spezifische Eigenschaften',
			cell: (row, rowIndex, column) => (
				<div className={'mb-0.5'}>
					{row.getDisplayed().properties.filter(elem => elem.necessity === Necessity.Specific).map((elem) =>
						<div className={'flex mt-0.5'}>
							<div>{elem.name}: &nbsp;</div>
							<div className={'text-blue-800'}>{elem.value} {elem.unit}</div>
						</div>
					)
					}
				</div>
			),
			width: '300px',
		},
		{
			name: 'Stückzahl',
			cell: (row, rowIndex, column) => (
				<div>{row.getDisplayed().amountAvailable}</div>
			),
			center: true,
			width: '200px',
		},
		{
			name: 'Preis',
			cell: (row, rowIndex, column) => (
				<div>{row.getDisplayed().price / 100} €</div>
			),
			center: true,
			width: '110px',
		},
		{
			name: 'Angebotspreis',
			cell: (row, rowIndex, column) => {
				if (row.getDisplayed().effectiveSale) {
					return <div
						className={'text-red-600'}> {row.getDisplayed().effectiveSale?.percentage ?
							(calcSalePrice(row.getDisplayed().price, row.getDisplayed().effectiveSale?.percentage ?? 0) / 100 + ' €').replace('.', ',') : '-'}
					</div>;
				} else {
					return <div className={'text-red-600'}> - </div>;
				}
			},
			center: true,
			width: '110px',
		},
		{
			name: 'Reduziert',
			cell: (row, rowIndex, column) => {
				const percentage: number | undefined = row.getDisplayed().effectiveSale?.percentage;
				if (percentage) {
					return <div
						className={'text-red-600'}> {(percentage / 100 + ' %').replace('.', ',')}</div>;
				} else {
					return <div className={'text-red-600'}> - </div>;
				}
			},
			center: true,
			width: '110px',
		},
		{
			name: 'Angebotsende',
			cell: (row, rowIndex, column) => {
				const endDate: string | undefined = row.getDisplayed().effectiveSale?.endDate;
				if (endDate) {
					return <div className={'text-red-600'}>
						{FormatDate(endDate)}
					</div>;
				} else {
					return <div className={'text-red-600'}> - </div>;
				}
			},
			width: '140px',
			center: true,
		},
		{
			name:'Status',
			cell: (row, rowIndex, column) =>
				<div className="flex flex-col items-center">
					{row.getDisplayed().active ?
						<span className="inline-flex rounded-full bg-green-200 px-2 font-semibold leading-5 text-black">
							Aktiv
						</span>:
						<span className="inline-flex rounded-full bg-yellow-300 px-2 font-semibold leading-5 text-black">
							Inaktiv
						</span>
					}
				</div>,
			center:true,
			width: '180px'
		},
		{
			name:'',
			right:true,
			grow:1,
			cell: (row, rowIndex, column) =>
				<div className="flex items-center gap-2 pr-4">
					<IconButton
						onClick={() => onOpenEditModal(row.getId())}
						icon={IconType.PENCIL}
						title='Produkt bearbeiten'/>
					<IconButton
						onClick={() => onOpenDelteModal(row.getId())}
						icon={IconType.TRASH}
						title='Produkt löschen'/>
				</div>
		},
	];


	return(
		<div className={classNames(wrapperClasses)}>
			{!isEditPropertiesTableVisible && (isEditPropertiesTableVisible ? !isEditPropertiesTableVisible : true) &&
					<div className={'flex flex-col w-full'}>

						<div className={'flex gap-16 pt-5'}>
							<div>
								<div className={'text-xl'}>Varianten Eigenschaft</div>
								<div>&#x2022; {properties.find((elem)=>elem.necessity===Necessity.Variation)?.name??''}</div>
							</div>

							<div>
								<div className={'text-xl'}>Übergreifende Eigenschaften</div>
								<div className={'flex flex-col'}>
									{properties.filter((elem)=>elem.necessity === Necessity.Universal)?.map((property)=>
										<div>
											&#x2022; {property.name}: {property.value} {property.unit}
										</div>
									)}
								</div>
							</div>

							<div>
								<div className={'text-xl'}>Spezifische Eigenschaften</div>
								<div className={'flex flex-col'}>
									{properties.filter((elem)=>elem.necessity === Necessity.Specific)?.map((property)=>
										<div>&#x2022; {property.name} {(property?.unit? ' (' + property?.unit + ')':'') }</div>
									)}
								</div>
							</div>

							<Button
								label={'Eigenschaften bearbeiten'}
								onClick={()=>setIsEditPropertiesTableVisible(true)}/>
						</div>

						<Button
							wrapperClasses={'pt-10'}
							onClick={onOpenVariantModal}
							label={'Variante Hinzufügen'} />

						{isCreateModalVisible &&
							<VariantChangeModal
								existingVariationPropertyValues={variantBuilderMap.getAllVariationProperties()}

								variationProperty={properties.find((elem) => elem.necessity === Necessity.Variation) ??
								{name: '', unit: '', value: '', filterId: '', necessity: ''}}

								universalProperties={properties.filter((elem) => elem.necessity === Necessity.Universal)}
								specificProperties={properties.filter((elem) => elem.necessity === Necessity.Specific)}
								onSuccess={onAddNewVariant}
								isSalePossible={isSalePossible}
								isVisible={isCreateModalVisible}
								onClose={onCloseVariantModal}/>
						}

						{isEditModalVisible && idToEdit && variantBuilderMap.map.get(idToEdit) &&
							<VariantChangeModal
								existingVariationPropertyValues={variantBuilderMap.getAllVariationProperties(idToEdit)}
								existingVariant={variantBuilderMap.map.get(idToEdit)?.getChange()}

								variationProperty={properties.find((elem) => elem.necessity === Necessity.Variation)
								?? {name:'', unit:'', value:'', filterId:'', necessity:''}}

								universalProperties={properties.filter((elem) => elem.necessity === Necessity.Universal)}
								specificProperties={properties.filter((elem) => elem.necessity === Necessity.Specific)}
								isSalePossible={isSalePossible}
								isVisible={isEditModalVisible}
								onClose={onCloseEditModal}
								onSuccess={onSuccessEditModal}/>
						}
						<DeleteModal
							isVisible={isDeleteModalVisible}
							onClose={onCloseDeleteModal}
							onSuccess={onSuccessDeleteModal}
							nameObjectToDelete={'Variante'}/>

						<DataTable
							columns={columns}
							keyField={'id'}
							data={variantBuilderMap.getAllNonDeleted()}/>
					</div>
			}

			{isEditPropertiesTableVisible &&
				<EditPropertiesTable
					onClose={() => setIsEditPropertiesTableVisible(false)}
					onSuccess={(properties) => onSuccessEditPropertiesTable(properties)}
					categoryId={categoryId}
					properties={properties}/>
			}
		</div>
	);
}
