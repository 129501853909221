import {useErrorHandling} from '../../../../../providers/error/ErrorProvider';
import React, {useRef, useState} from 'react';
import {Queries, Variant} from '../../../../../interfaces';
import {API} from '../../../../../api';
import Button, {ButtonType} from '../../../../../components/button/Button';
import {DeleteVariantSaleModal} from '../../normalSale/component/DeleteVariantSaleModal';
import DataTable, {TableColumn} from 'react-data-table-component';
import {endPointGetImage} from '../../../../../constants';
import uuid from 'react-uuid';
import {ManageVariantSpecialConditionExpand} from './component/ManageVariantSpecialConditionExpand';
import {
	ListVariantFunction,
	VariantSearchAndFiltering
} from '../../../../../components/variantSearchAndFiltering/variantSearchAndFiltering';


const customStyles = {
	expanderButton: {
		style: {
			minHeight: '72px', // override the row height
			'&:focus': {
				outline: 'none',
				backgroundColor: '#F5F5F5',
			},
		},
	},
};


const listVariantsQueries: Queries = {
	hasVariantSaleSpecialCondition: true
};


export default function ManageVariantSpecialCondition() {

	const variantRef = useRef<ListVariantFunction>(null);

	const errorHandling = useErrorHandling();

	const [loading, setLoading] = useState(false);
	const [totalAmount, setTotalAmount] = useState<number>(0);
	const [loadMorePossible, setLoadMorePossible] = useState<boolean>(false);

	const [variants, setVariants] = useState<Variant[]>([]);
	const [selectedVariants, setSelectedVariants] = useState<Variant[]>([]);

	const [hover, setHover] = useState<string>('');
	const [toggleSelectedRowClear, setToggleSelectedRowClear] = useState<boolean>(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);


	async function onDeleteSpecialConditions(variantIds: string[]) {
		try {
			await API.VariantSale.softDeleteMany(variantIds);

			await variantRef.current?.listVariants(false);

			setToggleSelectedRowClear(!toggleSelectedRowClear);
			setIsDeleteModalVisible(false);
		} catch (e: any) {
			errorHandling(e);
		}
	}


	const handleRowEntered = (row: { id: any; }) => {
		setHover(row.id);
	};


	const handleRowLeave = (row: { id: any; }) => {
		setHover('');
	};


	const handleOnSelect = (selected: { allSelected: boolean; selectedCount: number; selectedRows: Variant[]; }) => {
		setSelectedVariants(selected.selectedRows);
	};


	const colums: TableColumn<Variant>[] = [
		{
			name: 'Produktbild',
			cell: (row, rowIndex, column) => (
				<div
					style={hover === row.id || selectedVariants.some(item => item.id === row.id) ? {opacity: 0.2} : {}}>
					{row.imageIds && (row.imageIds.length > 0) ?
						<img
							src={endPointGetImage(row.imageIds[0])}
							className="w-20 h-20 object-scale-down"
							alt={''}
							id={'img' + uuid()}
						/>
						: <></>
					}
				</div>
			),
			center: true,
			width: '180px',
		},
		{
			name: 'Name',
			selector: row => row.name,
			center: true,
			width: '180px'
		},
		{
			name: 'Hersteller',
			selector: row => row.manufacturer?.name ?? '',
			center: true,
			width: '180px'
		},
		{
			name: 'Kategorie',
			selector: row => row.category?.name ?? '',
			center: true,
			width: '250px'
		},
		{
			name: 'Preis',
			cell: (row, rowIndex, column) => (
				<div>
					{row.price / 100} €
				</div>
			),
			center: true,
			width: '180px'
		},
		{
			name: 'Eigenschaften',
			cell: (row, rowIndex, column) => (
				row.properties.map((item) => (
					<div className={'flex pl-3'}>
						<div>
							{item.name} :&nbsp;
						</div>
						<div>
							{item.value}&nbsp;{item.unit ?? ''}&nbsp;&nbsp;&nbsp;
						</div>
					</div>

				))
			),
			width: '520px'
		}
	];


	return (
		<div className="px-4 sm:px-6 lg:px-8 mt-8">
			<div className="flex flex-col flex-1">

				<Button
					disabled={(selectedVariants.length === 0) ?? false}
					wrapperClasses={'self-end'}
					type={ButtonType.BUTTON}
					onClick={() => setIsDeleteModalVisible(true)}
					label="Rabatte löschen"/>

				<VariantSearchAndFiltering
					setLoading={setLoading}
					ref={variantRef}
					variants={variants}
					setVariants={setVariants}
					setTotalAmount={setTotalAmount}
					setLoadMorePossible={setLoadMorePossible}
					optionalQueries={listVariantsQueries}
				/>

				<DataTable
					keyField={'id'}
					columns={colums}
					data={variants}
					customStyles={customStyles}
					paginationTotalRows={totalAmount}
					progressPending={loading}
					selectableRows={true}
					clearSelectedRows={toggleSelectedRowClear}
					selectableRowsHighlight={true}
					highlightOnHover={true}
					onRowMouseEnter={handleRowEntered}
					onRowMouseLeave={handleRowLeave}
					onSelectedRowsChange={handleOnSelect}
					noDataComponent={<div>Keine Produkte vorhanden...</div>}
					expandableRows={true}
					expandableRowsComponent={(c) =>
						<ManageVariantSpecialConditionExpand
							onDelete={(variantId: string) => onDeleteSpecialConditions([variantId])}
							{...c}
						/>
					}
				/>


				<DeleteVariantSaleModal
					message={'Sind sie sicher, dass sie die Sonderkonditionen auf folgende Produkte löschen möchten?'}
					variants={selectedVariants}
					isVisible={isDeleteModalVisible}
					onClose={() => null}
					onSuccess={() => onDeleteSpecialConditions(selectedVariants.map(item => item.id))}/>


				{loadMorePossible &&
					<div className={'pt-3 flex justify-center'}>
						<Button
							type={ButtonType.BUTTON}
							label="load more"
							onClick={() => variantRef.current?.listVariants(false).then()}
						/>
					</div>
				}
			</div>
		</div>
	);
}