export type APIError = {
    errorId: string
    errorMessage: string
}


export const ErrorIdDE: { [errorID: string]: string } = {
	VARIANT_SALE_EXISTS: 'Rabatt existiert bereits',
	PARTNERSHIP_DOESNT_EXIST: 'Partnerschaft nicht existent',
	NETWORK: 'Netzwerkfehler',
	BINDING_URI: 'Fehlerhafte Anfrage'
};


export const ErrorMessagesDE: { [errorID: string]: string } = {
	BAD_REQUEST: 'Fehlerhafte Daten',
	NETWORK: 'Netzwerkfehler ist aufgetreten',
	BINDING_URI: 'Daten sind fehlerhaft oder unvollständig',
	VARIANT_SALE_EXISTS: 'Rabatt auf ein Produkt existiert bereits',
	PARTNERSHIP_DOESNT_EXIST: 'Angebene Partnerschaft existiert nicht'
};

