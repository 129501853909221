import classNames from 'classnames';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import IconButton, {IconType} from '../../../../components/button/IconButton';
import {ButtonType} from '../../../../components/button/Button';
import {FileInterface} from '../../../../interfaces';

const customStyles = {
	head: {
		style: {
			fontSize: '18px',
			fontWeight: 500,
		},
	},
};

interface Props {
  planIds: string[];
  dataSheetIds: string[];
  dxfFileIds: string[];
  stpFileIds: string[];
  wrapperClasses?: string;
}



function FilesAndDownloads(
	{
		planIds,
		dataSheetIds,
		dxfFileIds,
		stpFileIds,
		wrapperClasses
	}: Props) {

	const [files, setFiles] = useState<FileInterface[]>([]);

	useEffect(() => {

		let allFileIds:string[] = [];
		
		if (planIds) {
			allFileIds = allFileIds.concat(planIds);
		}
		if (dataSheetIds) {
			allFileIds = allFileIds.concat(dataSheetIds);
		}
		if (dxfFileIds) {
			allFileIds = allFileIds.concat(dxfFileIds);
		}
		if (stpFileIds) {
			allFileIds = allFileIds.concat(stpFileIds);
		}

		
		if (allFileIds.length > 0) {
			axios.get('files' , {params:{'fileIds': allFileIds}})
				.then((response) => {
					console.log('files', response.data['files']);
					setFiles(response.data['files']);
				})
				.catch(errors => {
					console.log(errors);
				});
		}
	}, []);

	const fileDownload = async (fileId: string) => {
		if (fileId !== '') {
			window.open(axios.defaults.baseURL + '/files/' + fileId + '/download', '_blank');
		}
	};

	const colums: TableColumn<FileInterface>[] = [
		{
			name:'Typ',
			selector: row => row.fileType,
			center:true,
			width: '180px'
		},
		{
			name:'Name',
			selector: row => row.name,
			center:true,
			width: '180px'
		},
		{
			name: '',
			cell: (row, rowIndex, column) => (
				<div>
					<IconButton
						wrapperClasses="self-center"
						icon={IconType.DOWNLOAD}
						onClick={()=>fileDownload(row.id)}
						type={ButtonType.BUTTON}
					/>
				</div>
			),
			center: true,
			width: '80px',
		},
	];


	return (
		<div className={classNames(wrapperClasses)}>
			<div className="flex justify-center text-2xl font-semibold">
					Downloads
			</div>

			{files && (files.length > 0) &&
				<DataTable
					columns={colums}
					data={files}
					customStyles={customStyles}
				/>
			}
		</div>
	);
}

export default FilesAndDownloads;
