import {Country, CountrysISO3166_1} from '../../../../utils/country';
import React from 'react';
import classNames from 'classnames';
import Divider from '../../../../components/Divider';
import InputField, {InputFieldType} from '../../../../components/inputField/InputField';
import ComboBox from '../../../../components/comboBox/ComboBox';


interface Props {
	title			: string
	setTitle		: React.Dispatch<React.SetStateAction<string>>
	country			: Country
	setCountry		: React.Dispatch<React.SetStateAction<Country>>
	zip 			: string
	setZip			: React.Dispatch<React.SetStateAction<string>>
	city			: string
	setCity			: React.Dispatch<React.SetStateAction<string>>
	street			: string
	setStreet		: React.Dispatch<React.SetStateAction<string>>
	streetNumber	: string
	setStreetNumber	: React.Dispatch<React.SetStateAction<string>>
	wrapperClasses? : string
}

function AddressSelection(
	{
		title, setTitle,
		country, setCountry,
		zip, setZip,
		city, setCity,
		street, setStreet,
		streetNumber, setStreetNumber,
		wrapperClasses
	} : Props) {


	return(
		<div className={classNames(wrapperClasses)}>
			<Divider title="Adresse" />

			<InputField
				wrapperClasses="py-2"
				type={InputFieldType.TEXT}
				value={title}
				title="Standort-Name"
				onChange={(e) => setTitle(e.target.value)}/>

			<ComboBox
				wrapperClasses="py-2"
				title="Land"
				items={CountrysISO3166_1.map(object => ({key: object.code, label: object.name, object: object}))}
				onChangeSelected={setCountry}/>

			<div className="flex flex-row">
				<InputField
					wrapperClasses="w-1/2 py-2"
					type={InputFieldType.TEXT}
					value={zip}
					title="Postleitzahl"
					onChange={(e) => setZip(e.target.value)}/>

				<InputField
					wrapperClasses="w-1/2 py-2"
					type={InputFieldType.TEXT}
					value={city}
					title="Stadt"
					onChange={(e) => setCity(e.target.value)}/>
			</div>

			<div className="flex flex-row">
				<InputField
					wrapperClasses="w-1/2 py-2"
					type={InputFieldType.TEXT}
					value={street}
					title="Straße"
					onChange={(e) => setStreet(e.target.value)}/>

				<InputField
					wrapperClasses="w-1/2 py-2"
					type={InputFieldType.TEXT}
					value={streetNumber}
					title="Hausnummer"
					onChange={(e) => setStreetNumber(e.target.value)}/>
			</div>
		</div>
	);
}

export default AddressSelection;