import {useNavigate, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useAuth} from '../../../providers/auth/AuthProvider';
import {APP_ROUTES} from '../../../constants';


const MailVerificationRedirect = () => {
	const navigate = useNavigate();
	const auth = useAuth();
	const {linkHash} = useParams<string>();

	useEffect(() => {
		const login = async () => {
			const res = await auth.mailVerificationLogin(linkHash);
			if (res.ok){
				navigate(APP_ROUTES.dashboard);
			} else {
				console.log(res.message);
			}
		};

		login().then();
	},[]);

	return(
		<div className="text-4xl">
			Sie haben ihren Account bestätigt. <br/>
			Die Registrierung ist nun abgeschlossen. <br/>
			Sie werden in kürze weitergeleitet. <br/>
		</div>
	);
};

export default MailVerificationRedirect;