import React from 'react';
import {Modal} from './Modal';
import Button, {ButtonType} from '../button/Button';
import {IconType} from '../button/IconButton';

interface Props {
	isVisible: boolean
	onClose: () => void
	onSuccess: () => void
    nameObjectToDelete: string
}

function DeleteModal({onClose, onSuccess, nameObjectToDelete, isVisible}: Props) {

	return(
		<Modal isOpen={isVisible} handleClose={onClose}>
			<div className="relative z-10">
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-5 text-center sm:p-0">

						<div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl
						transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">

							<div className="flex flex-row justify-end">
								<button
									type="button"
									className="text-2xl"
									onClick={onClose}
								>
									X
								</button>
							</div>
							<div>Sind sie sicher, dass sie {nameObjectToDelete} löschen wollen?</div>


							<Button
								wrapperClasses="pt-5"
								onClick={()=>{
									onSuccess();
								}}
								type={ButtonType.BUTTON}
								label="Löschen"
								icon={IconType.CHECK}/>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default DeleteModal;