import React, {useEffect, useRef} from 'react';
import {CSSTransition} from 'react-transition-group';
import ReactPortal from './ReactPortal';

interface Props {
  children: any,
  isOpen: boolean,
  handleClose: any
}

export const Modal = ({  children, isOpen, handleClose }: Props) => {

	const nodeRef = useRef(null);

	useEffect(() => {
		const closeOnEscapeKey = (e: any) => {
			if (!isOpen) return;

			if (e.key === 'Escape') {
				handleClose();
			}
		};

		document.body.addEventListener('keydown', closeOnEscapeKey);
		return () => {
			document.body.removeEventListener('keydown', closeOnEscapeKey);
		};

	}, [handleClose, isOpen]);

	return (
		<ReactPortal wrapperId="modal">
			<CSSTransition
				in={isOpen}

				timeout={{ enter: 0, exit: 300 }}
				unmountOnExit
				classNames="modal"
				nodeRef={nodeRef}
			>
				<div className="absolute inset-0 z-50" ref={nodeRef}>
					{isOpen && children}
				</div>
			</CSSTransition>
		</ReactPortal>
	);
};
