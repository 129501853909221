import axios from 'axios';
import React, {createContext, useContext, useState} from 'react';
import {Constants, ENDPOINTS} from '../../constants';
import {useCookies} from 'react-cookie';
import {User} from '../../interfaces';


export interface AuthContextType {
	user: User | null;
	login: (username: string, password: string) => Promise<AuthResult>;
	mailVerificationLogin: (linkHash:string|undefined) => Promise<AuthResult>;
	logout: () => void;
	loggedIn : boolean | null;
}

export type AuthResult = {
    ok: boolean,
    message: string | undefined
}

const AuthContext = createContext<AuthContextType>({
	user: null,
	login: (username: string, password: string) => {throw new Error('Not Implemented');},
	mailVerificationLogin: (linkHash:string|undefined) => {throw new Error('Not Implemented');},
	logout: () => null,
	loggedIn: null
});


export const useAuth = () => {
	return useContext(AuthContext);
};


interface JWTToken {
  mail: string;
  id:string;
  userType:string;
  v:number;
  iss:string;
  exp:number;
  iat:number;
}


interface Props {
  children: React.ReactNode
}


export const AuthProvider: React.FC<Props> = (props: Props) => {
	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const [token, setToken] = useState<string>(cookies.token);
	const [user, setUser] = useState<User | null>(null);
	const [loggedIn, setLoggedIn] = useState<boolean | null>(null); // as long as value is null, we're initializing

	React.useEffect(() => {
		if (token) {
			console.log('token valid');
			setCookie('token', token, { path: '/' });
			axios.defaults.headers['Authorization'] = 'Bearer ' + token;
			setLoggedIn(true);
		} else {
			console.log('token invalid');
			removeCookie('token', { path: '/' });
			delete axios.defaults.headers['Authorization'];
			setLoggedIn(false);
		}
	}, [token]);

	const fetchUser = async () => {
		if (!token) {
			setUser(null);
			return;
		}

		try {
			const res = await axios.get(ENDPOINTS.getUser);
			setUser(res.data);
		} catch (e) {
			console.log(e);
			setLoggedIn(false);
			setUser(null);
		}
	};

	React.useEffect(() => {
		fetchUser().then();
	}, [token]);


	const login = async (mail: string, password: string) => {
		try {
			const response = await axios.post(ENDPOINTS.login, { 'mail': mail, 'password': password });
			const user:User = response.data['user'];
			
			if( ! user.permissionGroup?.permissions?.includes(Constants.Permissions.WebUse)){
				return {
					ok: false,
					message: 'Sie sind nicht berechtigt sich in das Admin-Portal einzuloggen.'
				};
			}

			setToken(response.data['token']);
			return {
				ok: true,
				message: undefined
			};
		} catch (e) {
			setToken('');
			console.log(e);
			return {
				ok: false,
				message: 'Nutzername oder Passwort fehlerhaft.'
			};
		}
	};

	
	const mailVerificationLogin = async (linkHash:string|undefined) => {
		try{
			console.log(linkHash);
			const response = await axios.post(ENDPOINTS.finishCompanyRegistration,
				{
					'linkHash': linkHash
				});
			setToken(response.data['token']);
			return {
				ok: true,
				message: undefined
			};

		} catch (e) {
			console.log('mail verification login failed', e);
			setToken('');
			return {
				ok: false,
				message: 'Weiterleitung fehlerhaft.'
			};
		}
	};


	const logout = () =>{
		setToken('');
	};


	const value = { user, login, mailVerificationLogin, logout, loggedIn };
	return (
		<AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
	);
};
