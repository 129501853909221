import {Fragment} from 'react';
import {Transition} from '@headlessui/react';
import {CheckCircleIcon} from '@heroicons/react/24/outline';
import {XMarkIcon} from '@heroicons/react/20/solid';
import {Notification, NotificationType} from './NotificationProvider';
import {HiBan, HiOutlineInformationCircle} from 'react-icons/hi';


interface Props {
    notification: Notification
	show: boolean
	setShow: React.Dispatch<React.SetStateAction<boolean>>
	afterLeave: () => void;
}

export default function NotificationLayout({notification, show, setShow, afterLeave}: Props) {
	return (
		<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
			<Transition
				show={show}
				as={Fragment}
				enter="transform ease-out duration-300 transition"
				enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				enterTo="translate-y-0 opacity-100 sm:translate-x-0"
				leave="transition ease-in duration-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				afterLeave={afterLeave}
			>
				<div
					className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
				>
					<div className="p-4">
						<div className="flex items-start">
							<div className="flex-shrink-0">
								{notification.type === NotificationType.success ?
									<CheckCircleIcon
										className="h-6 w-6 text-green-400"
										aria-hidden="true"/> :
									notification.type === NotificationType.hint ?
										<HiOutlineInformationCircle
											className="h-6 w-6 text-yellow-400"
											aria-hidden="true"/> :
										notification.type === NotificationType.failure &&
											<HiBan
												className="h-6 w-6 text-red-400"
												aria-hidden="true"/>
								}
							</div>
							<div className="ml-3 w-0 flex-1 pt-0.5">
								<p className="text-sm font-medium text-gray-900">{notification.title}</p>
								<p className="mt-1 text-sm text-gray-500">{notification.message}</p>
							</div>
							<div className="ml-4 flex flex-shrink-0">
								<button
									type="button"
									className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									onClick={() => {
										setShow(false);
									}}
								>
									<span className="sr-only">Close</span>
									<XMarkIcon className="h-5 w-5" aria-hidden="true"/>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</div>
	);
}