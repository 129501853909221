import {Modal} from '../../../../components/modal/Modal';
import React, {useState} from 'react';
import axios from 'axios';
import Button, {ButtonType} from '../../../../components/button/Button';
import {IconType} from '../../../../components/button/IconButton';
import {endPointVariantSoftDelete} from '../../../../constants';
import {Variant} from '../../../../interfaces';


interface Props {
	isVisible: boolean
    onClose: ()=>void
	onSuccess: () => void
	variant: Variant
}

function DeleteProductModal({ onClose, onSuccess, variant, isVisible}:Props) {
	const [inputFieldError, setInputFieldError] = useState<string>('');


	const onDeleteButtonClick = async () => {
		setInputFieldError('');

		axios.put(endPointVariantSoftDelete(variant.productId, variant.id))
			.then(function (response) {
				setInputFieldError('');
				onSuccess();
				onClose();
			})
			.catch(errors => {
				setInputFieldError('Produkt löschen fehlgeschlagen');
			});
		return false;
	};

	return(
		<Modal isOpen={isVisible} handleClose={onClose}>
			<div className="relative z-10">
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-5 text-center sm:p-0">

						<div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl
						transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
							

							<div className="flex flex-row justify-end">
								<button
									type="button"
									className="text-2xl"
									onClick={onClose}
								>
									X
								</button>
							</div>
							<div>Sind sie sicher, dass sie das Produkt löschen wollen?</div>


							<Button
								wrapperClasses="pt-5"
								onClick={()=>onDeleteButtonClick()}
								type={ButtonType.BUTTON}
								label="Löschen"
								icon={IconType.CHECK}/>

							{inputFieldError != '' &&
								<div className="flex justify-center">
									<div className="text-red-600 py-2">{inputFieldError}</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default DeleteProductModal;