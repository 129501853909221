import React, {FormEvent, useState} from 'react';
import Button, {ButtonType} from '../../../components/button/Button';
import {mailRegEx} from '../../../utils';
import axios from 'axios';
import Divider from '../../../components/Divider';
import InputField, {InputFieldType} from '../../../components/inputField/InputField';
import {Modal} from '../../../components/modal/Modal';

import {useAuth} from '../../../providers/auth/AuthProvider';
import ComboBox from '../../../components/comboBox/ComboBox';
import {IconType} from '../../../components/button/IconButton';
import PermissionsSelection from './PermissionsSelection';
import {Constants, endPointUserBlock, endPointUserSetActiveState, endPointUserUnblock} from '../../../constants';
import {BudgetRefresh, PermissionGroup, User} from '../../../interfaces';
import SupervisorSelection from './SupervisorSelection';
import Budget from './Budget';


interface Props {
	isVisible: boolean
	onClose: ()=>void
	onSuccess: ()=>void
	user:User
}

export interface UserState {
	name: string
}
const UserStates: UserState[] = [
	{name:Constants.UserStates.Active},
	{name:Constants.UserStates.Inactive},
	{name:Constants.UserStates.Blocked},
	{name:Constants.UserStates.Invited}
];


function EditUserModal({ isVisible, onClose, onSuccess, user }:Props) {
	const auth = useAuth();

	const [userState,setUserState] = useState<UserState>((): UserState => {
		const state = UserStates.find((item) => item.name == user.userState);
		return state ? state : {name:'error'};
	});
	const [mail, setMail] = useState<string>(user.mail);
	const [firstName, setFirstName] = useState<string>(user.firstName);
	const [lastName, setLastName] = useState<string>(user.lastName);
	const [department, setDepartment] = useState<string>(user.department);
	const [pickingNumber, setPickingNumber] = useState<string>(user.pickingNumber);

	const [hasUnlimitedBudget, setHasUnlimitedBudget] = useState<boolean>(false);
	const [budget, setBudget] = useState<number>(Math.floor(user.budget / 100) );

	const [budgetRefresh, setBudgetRefresh] = useState<BudgetRefresh | undefined>(user.budgetRefresh);

	const [selectedSupervisor, setSelectedSupervisor] = useState<User | undefined>(user.supervisor);
	const [selectedPermissionGroup, setSelectedPermissionGroup] = useState<PermissionGroup|undefined>(user.permissionGroup);
	const [webUsePermission, setWebUsePermission] = useState<boolean>(false);
	const [appUsePermission, setAppUsePermission] = useState<boolean>(false);

	const [inputFieldError, setInputFieldError] = useState<string>('');


	async function handleUserState():Promise<any>{
		if (userState.name === user.userState){
			return new Promise((resolve, reject) =>{
				resolve('nothing changed');
			});
		}

		if(user.userState === Constants.UserStates.Invited){
			return new Promise((resolve, reject)=>{
				reject('Der Status eines eingeladenen Benutzers kann nicht geändert werden.');
			});
		}

		if((userState.name === Constants.UserStates.Blocked) && (user.id === auth.user?.id)){
			return new Promise((resolve, reject)=>reject('Sie können sich nicht selbst blockieren...'));

		} else if (userState.name === Constants.UserStates.Blocked){
			await axios.put(endPointUserBlock(user.id))
				.then(function (response) {
					return new Promise((resolve, reject)=>{
						resolve('user blocked successfully');
					});
				})
				.catch(errors => {
					return new Promise((resolve, reject)=>reject('Blocking user failed'));
				});
		}

		if ((userState.name === Constants.UserStates.Active ) && (user.userState === Constants.UserStates.Blocked)){
			await axios.put(endPointUserUnblock(user.id))
				.then(function (response) {
					return new Promise((resolve, reject)=>resolve('userState changed to active'));
				})
				.catch(errors => {
					return new Promise((resolve, reject) => reject('Activating user failed'));
				});
		} else if((userState.name === Constants.UserStates.Active ) && (user.userState === Constants.UserStates.Inactive)){
			await axios.put(endPointUserSetActiveState(user.id), {active:true})
				.then(function (response) {
					return new Promise((resolve, reject)=>resolve('userState changed to active'));
				})
				.catch(errors => {
					return new Promise((resolve, reject)=>reject('Activating user failed'));
				});
		}

		if ((userState.name === Constants.UserStates.Inactive) && (user.userState === Constants.UserStates.Active)){
			await axios.put(endPointUserSetActiveState(user.id), {active:false})
				.then(function (response) {
					return new Promise((resolve, reject)=>resolve('userState changed to inactive'));
				})
				.catch(errors => {
					return new Promise((resolve, reject)=>reject('inactivating user failed'));
				});
		}
	}


	const onFinishButtonClick = async (event: FormEvent<HTMLFormElement>) =>{
		event.preventDefault();
		setInputFieldError('');

		if (mail == '') {
			setInputFieldError('E-Mail fehlt');
		} else if (!mailRegEx.test(mail)) {
			setInputFieldError('Ungültige E-Mail');
		} else if (firstName == '') {
			setInputFieldError('Vorname fehlt');
		} else if (lastName == '') {
			setInputFieldError('Nachname fehlt');
		} else if (selectedPermissionGroup === undefined) {
			setInputFieldError('Berechtigungsgruppe fehlt');
		} else if (budget > 100000000) {
			setInputFieldError('Budget darf 1000000€ nicht überschreit');
		} else if (!hasUnlimitedBudget && budgetRefresh !== undefined && budgetRefresh.value === 0) {
			setInputFieldError('Budget für das Intervall fehlt');
		} else if (!hasUnlimitedBudget && budgetRefresh !== undefined && budgetRefresh.value > 1000000) {
			setInputFieldError('Budget Intervall darf 1000000€ nicht überschreit');
		} else if (!selectedSupervisor && selectedPermissionGroup.name === Constants.PermissionGroupNames.Worker) {
			setInputFieldError('Berechtigungsgruppe braucht einen Vorgesetzten');
		} else if (department == '') {
			setInputFieldError('Abteilung fehlt');
		} else {
			axios.put( 'user/' + user.id,
				{
					'mail': mail,
					'firstName': firstName,
					'lastName': lastName,
					'department': department,
					'pickingNumber':pickingNumber,
					'budget': budget * 100,
					'budgetRefresh': budgetRefresh,
					'permissionGroupId': selectedPermissionGroup?.id,
					'supervisorId': selectedSupervisor ? selectedSupervisor.id : '',
					'unlimitedBudget': hasUnlimitedBudget
				})
				.then(async function (response) {
					handleUserState()
						.then((result)=>{
							setInputFieldError('');
							onSuccess();
						})
						.catch((error)=>{
							setInputFieldError(error);
						});
				})
				.catch(errors => {
					setInputFieldError('Bearbeitung des Benutzers fehlgeschlagen');
				});
		}

		return false;
	};

	const closeModal = () => {
		onClose();
	};

	return (
		<Modal isOpen={isVisible} handleClose={closeModal}>
			<div className="relative z-10">
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-5 text-center sm:p-0">

						<div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl
						transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">


							<form action="src/components/dashboard/UserAdministration#"
								onSubmit={(event) => onFinishButtonClick(event)}>

								<div className="flex flex-row justify-end">
									<button type="button" className="text-2xl" onClick={()=>onClose()}>X</button>
								</div>

								<Divider title="Benutzerdaten"/>
								<InputField
									wrapperClasses="py-2"
									type={InputFieldType.TEXT}
									value={mail}
									title="E-Mail"
									onChange={(e)=>setMail(e.target.value)}/>

								<div className="flex flex-row py-2">
									<InputField
										wrapperClasses="w-1/2"
										type={InputFieldType.TEXT}
										value={firstName}
										title="Vorname"
										onChange={(e)=>setFirstName(e.target.value)}/>

									<InputField
										wrapperClasses="w-1/2"
										type={InputFieldType.TEXT}
										value={lastName}
										title="Nachname"
										onChange={(e)=>setLastName(e.target.value)}/>
								</div>

								<InputField
									wrapperClasses="py-2"
									type={InputFieldType.TEXT}
									value={department}
									title="Abteilung"
									onChange={(e)=>setDepartment(e.target.value)}/>

								<InputField
									wrapperClasses="py-2"
									type={InputFieldType.TEXT}
									value={pickingNumber}
									title="Kommissionierungsnummer"
									onChange={(e)=>setPickingNumber(e.target.value)}/>

								<SupervisorSelection
									wrapperClasses="py-2"
									targetUserId={user.id}
									selectedSupervisor={selectedSupervisor}
									setSelectedSupervisor={setSelectedSupervisor}/>

								<PermissionsSelection
									appUsePermission={appUsePermission}
									setAppUsePermission={setAppUsePermission}
									webUsePermission={webUsePermission}
									setWebUsePermission={setWebUsePermission}
									selectedPermissionGroup={selectedPermissionGroup}
									setSelectedPermissionGroup={setSelectedPermissionGroup}/>

								<Budget
									hasUnlimitedBudget={hasUnlimitedBudget}
									setHasUnlimitedBudget={setHasUnlimitedBudget}
									initialBudget={budget}
									setInitialBudget={setBudget}
									budgetRefresh={budgetRefresh}
									setBudgetRefresh={setBudgetRefresh}/>

								<ComboBox
									wrapperClasses="py-2"
									title="Benutzerstatus"
									items={UserStates.map(object => ({
										key: object.name,
										label: object.name,
										object: object
									}))}
									onChangeSelected={setUserState}/>

								<Button
									wrapperClasses="py-4"
									type={ButtonType.SUBMIT}
									label="Fertig"
									icon={IconType.CHECK}/>

								{inputFieldError != '' &&
									<div className="flex justify-center">
										<div className="text-red-600 py-2">{inputFieldError}</div>
									</div>
								}
							</form>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default EditUserModal;
