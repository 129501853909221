import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Constants, ENDPOINTS} from '../../constants';
import DataTable, {SortOrder, TableColumn} from 'react-data-table-component';
import ExpandedOrdersRow from './ExpandedOrdersRow';
import FormatDate from '../../utils/date';
import PriceToComma from '../../utils/priceFormatting';
import Button, {ButtonType} from '../../components/button/Button';
import {OrderBundle} from '../../interfaces';


const customStyles = {
	expanderButton: {
		style: {
			minHeight: '72px', // override the row height
			'&:focus': {
				outline: 'none',
				backgroundColor: '#F5F5F5',
			},
		},
	},
};


export default function OrderBundlesTable() {

	const [loading, setLoading] = useState(false);
	const [totalAmount, setTotalAmount] = useState<number>(0);
	const [nextPageToken, setNextPageToken] = useState<string>('');
	const [dateSortDirection, setDateSortDirection] = useState(Constants.SortDirection.SortDESC);

	const [orderBundles, setOrderBundles] = useState<OrderBundle[]>([]);


	const loadOrderBundles = async (token:string) => {
		setLoading(true);

		const params = {
			'pageSize':15,
			'token':token,
			'fromCompany':true,
			'sortDirection':dateSortDirection,
			'sortField': Constants.OrderBundleSortField.BundleDate
		};

		axios.get(ENDPOINTS.listOrderBundles,
			{
				params:params
			})
			.then((response) => {
				if (token !== '') {
					setOrderBundles(orderBundles.concat(response.data['orderBundles']));
				} else {
					setOrderBundles(response.data['orderBundles']);
				}
				setNextPageToken(response.data['nextPageToken']);
				setTotalAmount(response.data['totalAmount']);
			})
			.catch(errors => {
				console.log(errors);
			});
		setLoading(false);
	};

	useEffect(() => {
		loadOrderBundles('').then();
	}, [dateSortDirection]);


	const onSortChange = async (selectedColumn: TableColumn<OrderBundle>, sortOrder: SortOrder, sortedRows: any[]) => {
		setOrderBundles([]);
		if (sortOrder === 'asc'){
			setDateSortDirection(Constants.SortDirection.SortASC);
		} else if (sortOrder == 'desc'){
			setDateSortDirection(Constants.SortDirection.SortDESC);
		}
	};

	const colums: TableColumn<OrderBundle>[] = [
		{
			name: 'Bündelnummer',
			selector: row => row.bundleNumber,
			center:true,
			width: '120px'
		},
		{
			name: 'Datum',
			selector: row => FormatDate(row.date),
			center:true,
			width: '180px',
			sortable: true,
		},
		{
			name:'Besteller*in',
			cell: (row, rowIndex, column) =>
				<div className="break-all">
					{row.user?.firstName}&nbsp;{row.user?.lastName}
				</div>,
			center:true,
			width: '250px'
		},
		{
			name:'Gesamtpreis',
			cell: (row, rowIndex, column) =>
				<div className="flex flex-col gap-1">
					<div>Netto: &nbsp;{PriceToComma(row.priceNet / 100)} €</div>
					<div>Brutto: {PriceToComma(row.priceGross / 100)} €</div>
				</div>,
			center:true,
			width: '180px'
		},
		{
			name:'Status',
			selector: row => row.status,
			center:true,
			width: '180px'
		},
		{
			name:'Lieferadresse',
			width: '250px',
			center:true,
			cell:(row, rowIndex, column) =>
				<div className="flex flex-col justify-center py-3">
					<div>{row.deliveryAddress.title}</div>
					<div>{row.deliveryAddress.country}</div>
					<div>{row.deliveryAddress.zip}&nbsp;{row.deliveryAddress.city}</div>
					<div>{row.deliveryAddress.street}&nbsp;{row.deliveryAddress.streetNumber}</div>
				</div>
		},
		{
			name:'Kommentar',
			width: '400px',
			center:true,
			cell: (row, rowIndex, column) =>
				<div className="flex break-all ">
					{row.comment}
				</div>
		},
	];

	return (
		<div className="pt-10 px-8">
			{orderBundles ?
				<div className="flex flex-col">
					<DataTable
						columns={colums}
						data={orderBundles}
						customStyles={customStyles}
						expandableRows
						expandableRowsComponent={ExpandedOrdersRow}
						progressPending={loading}
						defaultSortFieldId={2}
						defaultSortAsc={false}
						sortServer
						onSort={onSortChange}
					/>

					{(totalAmount > 15) && (orderBundles.length < totalAmount) &&
						<Button
							wrapperClasses="py-5 self-center"
							type={ButtonType.BUTTON}
							label="load more"
							onClick={()=>loadOrderBundles(nextPageToken)}
						/>
					}
				</div>
				:
				<div>Keine Bestellungen vorhanden...</div>
			}
		</div>
	);
}
