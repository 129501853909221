import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {endPointGetImage} from '../../../../../../../constants';
import IconButton, {IconType} from '../../../../../../../components/button/IconButton';
import {ButtonType} from '../../../../../../../components/button/Button';
import uuid from 'react-uuid';
import {FileInterface} from '../../../../../../../interfaces';


interface Props {
	fileType:string
	imageIds:string[]
	setImageIds: React.Dispatch<React.SetStateAction<string[]>>
	files: FileInterface[]
	setFiles: React.Dispatch<React.SetStateAction<FileInterface[]>>
	wrapperClasses?	:string
}

function EditImageSelectionList(
	{
		fileType,
		imageIds, setImageIds,
		files, setFiles,
		wrapperClasses
	} : Props) {


	const fileRef = React.useRef<HTMLInputElement>(null);
	const [newFile, setNewFile] = useState<File | null>(null);


	useEffect(() => {
		if (newFile) {
			const reader = new FileReader();
			reader.onload = () => {

				const arrBase64 = (reader.result as string).split(',');
				if (arrBase64.length == 2){
					setFiles(files.concat([{fileType:fileType, data:arrBase64[1], id:'', name:newFile.name, contentType:''}]));
				}
			};
			reader.readAsDataURL(newFile);
		}
	}, [newFile]);

	function DeleteImageId(index:number) {
		const newArr:string[] = structuredClone(imageIds);
		newArr.splice(index, 1);
		setImageIds(newArr);
	}

	function DeleteFile(index:number) {
		const newArr:FileInterface[] = structuredClone(files);
		newArr.splice(index, 1);
		setFiles(newArr);
	}

	return(
		<div className={classNames(wrapperClasses)}>

			<div className="w-96 flex-wrap py-2 pl-4 flex gap-2 border rounded-lg">

				{imageIds && imageIds.map((id, index) => (
					<div className="border rounded-lg hover:border-blue-700 relative group">
						<img
							src={endPointGetImage(id)}
							className="object-scale-down w-20 h-20 p-1"
							alt={'image preview'}
							id={id + uuid()}/>

						<IconButton
							wrapperClasses="absolute top-0 left-15 right-0 hidden group-hover:block"
							icon={IconType.TRASH}
							onClick={()=>DeleteImageId(index)}/>
					</div>
				))}

				{files && files.map((image, index) => (
					<div className="border rounded-lg hover:border-blue-700 relative group">

						<img
							src={'data:' + image.contentType + ';base64,' + image.data}
							alt={'image preview'}
							className="object-scale-down w-20 h-20 p-1"/>

						<IconButton
							wrapperClasses="absolute top-0 left-15 right-0 hidden group-hover:block"
							icon={IconType.TRASH}
							onClick={()=>DeleteFile(index)}/>
					</div>
				))}

				<input
					type="file"
					onChange={(e)=>setNewFile(e.target.files?e.target.files[0]:null)}
					style={{display:'none'}}
					ref={fileRef}
					accept="image/png, image/jpeg"/>

				<IconButton
					wrapperClasses="p-6 border rounded-lg"
					icon={IconType.PLUS}
					onClick={()=>fileRef.current?.click()}
					type={ButtonType.BUTTON}/>
			</div>
		</div>
	);
}

export default EditImageSelectionList;
