import React, {createContext, useContext, useEffect, useState} from 'react';
import NotificationLayout from './NotificationLayout';

export enum NotificationType {
    success,
    failure,
    hint
}

export type Notification = {
    type: NotificationType
    title: string
    message: string
}

export interface NotificationsContextType {
    notifications: Notification[]
    push: (type: NotificationType, title: string, message: string) => void;
}

const NotificationContext = createContext<NotificationsContextType>({
	notifications: [],
	push: (type: NotificationType, title: string, message: string) => null
});

export const useNotifications = () => {
	return useContext(NotificationContext);
};


interface Props {
    children: React.ReactNode
}

export const NotificationProvider: React.FC<Props> = (props: Props) => {
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [show, setShow] = useState<boolean>(true);

	useEffect(() => {
		if (notifications) {
			setShow(true);
		}
	}, [notifications]);

	// trigger notification leave transition
	useEffect(() => {
		const timer = setTimeout(() => {
			setShow(false);
		}, 20000);
		return () => clearTimeout(timer);
	}, [notifications]);


	const afterLeaveNotificationTransition = () => {
		setNotifications([]);
	};

	const push = (type: NotificationType, title: string, message: string) => {
		setNotifications(notifications.concat({type: type, title: title, message: message}));
	};

	const value = {notifications, push};
	return (
		<>
			<div
				aria-live="assertive"
				className="pointer-events-none fixed inset-0 flex flex-col items-end px-4 py-6 gap-2 sm:items-start sm:p-6 z-50"
			>
				{notifications && notifications.map((n) =>
					<NotificationLayout
						notification={n}
						show={show}
						setShow={setShow}
						afterLeave={afterLeaveNotificationTransition}/>)
				}
			</div>

			<NotificationContext.Provider value={value}>
				{props.children}
			</NotificationContext.Provider>
		</>
	);
};