import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ENDPOINTS} from '../../constants';
import DataTable, {TableColumn} from 'react-data-table-component';
import FormatDate from '../../utils/date';
import PriceToComma from '../../utils/priceFormatting';
import {Order, OrderBundle, OrderPosition} from '../../interfaces/orderBundle';


const colums: TableColumn<OrderPosition>[] = [
	{
		name:'Anzahl',
		selector: row => row.amount,
		center:true,
		width: '100px'
	},
	{
		name: 'Artikelnummer',
		selector: row => row.item?.articleNumber,
		center:true,
		width: '250px'
	},
	{
		name: 'Hersteller',
		selector: row => row.item?.manufacturer?.name??'',
		center:true,
		width: '250px'
	},
	{
		name: 'Kurzbeschreibung',
		cell:(row, rowIndex, column) =>
			<div>
				{row.item?.shortDescription}
			</div>,
		center:true,
		width: '250px'
	},
	{
		name: 'Einzelpreis',
		cell:(row, rowIndex, column) =>
			<div>
				{row.item?.effectiveSale?.price ?
					PriceToComma(row.item?.effectiveSale.price / 100)
					: row.item?.price?
						PriceToComma(row.item?.price / 100)
						: '-'} €
			</div>,
		center:true,
		width: '250px'
	},
	{
		name: 'Gesamtpreis',
		cell:(row, rowIndex, column) =>
			<div>
				{row.item?.effectiveSale?.price ?
					PriceToComma((row.item?.effectiveSale.price * row.amount) / 100)
					: row.item?.price?PriceToComma((row.item?.price * row.amount) / 100)
						:'-'} €
			</div>,
		center:true,
		width: '250px'
	},
	{} // Dummy
];

export default function OrderOverview() {

	const {bundleId} = useParams<string>();
	const {orderId} = useParams<string>();

	const [orderBundle, setOrderBundle] = useState<OrderBundle>();
	const [order, setOrder] = useState<Order>();


	const loadOrderBundle = async () => {
		axios.get(ENDPOINTS.getOrderBundle + bundleId,
			{
				params:{'resolveUser':true},
			})
			.then((response) => {
				console.log('getOrderBundle: ', response.data);
				setOrderBundle(response.data);
			})
			.catch(errors => {
				console.log(errors);
			});
	};

	const loadOrder = async () => {
		axios.get(ENDPOINTS.getOrder + orderId)
			.then((response) => {
				console.log('getOrder: ', response.data);
				setOrder(response.data);
			})
			.catch(errors => {
				console.log(errors);
			});
	};

	useEffect(() => {
		loadOrderBundle().then();
		loadOrder().then();
	}, []);


	return (
		<div className="flex flex-col gap-12 px-10 py-20">

			<div className="flex gap-20">
				{orderBundle && order &&
				<>
					<div>
						<div className="text-2xl">Besteller</div>
						<div>{orderBundle.user.firstName} {orderBundle.user.lastName} </div>
					</div>

					<div>
						<div className="text-2xl">Lieferadresse</div>
						<div>{orderBundle.deliveryAddress.title}</div>
						<div>{orderBundle.deliveryAddress.country}</div>
						<div>{orderBundle.deliveryAddress.zip} {orderBundle.deliveryAddress.city}</div>
						<div>{orderBundle.deliveryAddress.street} {orderBundle.deliveryAddress.streetNumber}</div>
					</div>

					<div>
						<div className="text-2xl">Verkäufer</div>
						<div>{order.vendorName}</div>
					</div>

					<div>
						<div className="text-2xl">Bestelldatum</div>
						<div>{FormatDate(orderBundle.date)}</div>
					</div>

					<div>
						<div className="text-2xl">Weitere Informationen</div>
						<div>Anzahl Artikel: {order.positions.length}</div>
						<div>Gesamtpreis netto: {PriceToComma(order.priceNet / 100)} €</div>
						<div>Gesamtpreis brutto: {PriceToComma(order.priceGross / 100)} €</div>
					</div>
				</>
				}
			</div>

			{order &&
				<div>
					<div className="text-2xl pb-5">Positionen</div>
					<DataTable columns={colums} data={order.positions}/>
				</div>
			}
		</div>
	);
}
