import React, {useEffect, useState} from 'react';
import CategoriesSelection from '../../products/management/create/components/CategoriesSelection';
import DataTable, {TableColumn} from 'react-data-table-component';
import {CategorySale, Company, SaleType, Variant} from '../../../interfaces';
import {APP_ROUTES, endPointGetImage} from '../../../constants';
import uuid from 'react-uuid';
import Button, {ButtonType} from '../../../components/button/Button';
import {IconType} from '../../../components/button/IconButton';
import {API} from '../../../api/API';
import {CreateCategorySaleModal} from './component/CreateCategorySaleModal';
import FormatDate from '../../../utils/date';
import ComboBox from '../../../components/comboBox/ComboBox';
import {CompanyPartnership} from '../../../interfaces/companyPartnership';
import {useNavigate} from 'react-router-dom';
import {useErrorHandling} from '../../../providers/error/ErrorProvider';

const customStyles = {
	expanderButton: {
		style: {
			minHeight: '72px', // override the row height
			'&:focus': {
				outline: 'none',
				backgroundColor: '#F5F5F5',
			},
		},
	},
};

export default function CreateCategorySale() {
	const navigate = useNavigate();
	const errorHandling = useErrorHandling();

	const [nextPageToken, setNextPageToken] = useState<string>('');
	const [totalAmount, setTotalAmount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);

	const [isCreateCategoryModalVisible, setIsCreateCategoryModalVisible] = useState<boolean>(false);
	const [variants, setVariants] = useState<Variant[]>([]);
	const [deepestCategoryId, setDeepestCategoryId] = useState<string>('');
	const [categorySales, setCategorySales] = useState<CategorySale[]>([]);
	const [partnerCompanies, setPartnerCompanies] = useState<Company[]>([]);
	const [partnerCompanyId, setPartnerCompanyId] = useState<string | undefined>(undefined);
	const [saleExists, setSaleExists] = useState<boolean>(false);
	const [saleOnParentExists, setSaleOnParentExists] = useState<boolean>(false);


	useEffect(() => {
		fetchPartnerCompanies().then();
	}, []);


	useEffect(() => {
		setVariants([]);

		if (deepestCategoryId) {
			loadVariants().then();
			loadCategorySales().then();
		} else {
			setCategorySales([]);
		}
	}, [deepestCategoryId, partnerCompanyId]);


	useEffect(() => {
		if (categorySales && partnerCompanyId && categorySales.find(elem => elem.buyerId === partnerCompanyId && (elem.categoryId === deepestCategoryId))) {
			setSaleExists(true);
		} else if (categorySales && !partnerCompanyId && categorySales.find(elem => elem.saleType === SaleType.normalSaleType && (elem.categoryId === deepestCategoryId))) {
			setSaleExists(true);
		} else {
			setSaleExists(false);
		}
	}, [JSON.stringify(categorySales), partnerCompanyId, deepestCategoryId]);


	useEffect(() => {
		if (categorySales && partnerCompanyId && categorySales.find(elem => elem.buyerId === partnerCompanyId && (elem.categoryId !== deepestCategoryId))) {
			setSaleOnParentExists(true);
		} else if (categorySales && !partnerCompanyId && categorySales.find(elem => elem.saleType === SaleType.normalSaleType && (elem.categoryId !== deepestCategoryId))) {
			setSaleOnParentExists(true);
		} else {
			setSaleOnParentExists(false);
		}
	}, [JSON.stringify(categorySales), partnerCompanyId, deepestCategoryId]);


	const loadVariants = async (token?: string) => {
		try {
			setLoading(true);
			const resp = await API.Variant.list([],
				{
					pageSize: 15,
					token: token ?? '',
					resolveFilters: true,
					fromCompany: true,
					categoryId: deepestCategoryId !== '' ? deepestCategoryId : undefined
				}
			);
			if (token && token !== '') {
				setVariants(variants.concat(resp.data['variants']));
			} else {
				setVariants(resp.data['variants']);
			}
			setNextPageToken(resp.data['nextPageToken']);
			setTotalAmount(resp.data['totalAmount']);
			setLoading(false);
		} catch (e: any) {
			errorHandling(e);
		}
	};


	const loadCategorySales = async () => {
		try {
			setLoading(true);
			const resp = await API.CategorySale.list({
				categoryIdInHierarchy: deepestCategoryId,
				resolveBuyer: true,
				resolveCategory: true
			});
			setCategorySales(resp.data['categorySales']);
			setLoading(false);
		} catch (e: any) {
			errorHandling(e);
		}
	};


	const onSuccesCreateCategorySale = async (percentage: number, endDate: string | undefined) => {
		try {
			await API.CategorySale.create(
				deepestCategoryId,
				partnerCompanyId ? SaleType.specialConditionSaleType : SaleType.normalSaleType,
				percentage,
				endDate,
				partnerCompanyId ?? undefined
			);
			setIsCreateCategoryModalVisible(false);
			navigate(APP_ROUTES.manageCategorySale);
		} catch (e: any) {
			errorHandling(e);
		}
	};


	const fetchPartnerCompanies = async () => {
		try {
			const resp = await API.CompanyPartnership.listAsSeller({});
			const companyPartnerships: CompanyPartnership[] = resp.data['companyPartnerships'];
			setPartnerCompanies(companyPartnerships.map(elem => elem.buyer ?? {
				name: '',
				id: '',
				imageId: '',
				buyer: false,
				seller: false,
				addresses: []
			}));
		} catch (e: any) {
			errorHandling(e);
		}
	};


	const colums: TableColumn<Variant>[] = [
		{
			name: 'Produktbild',
			cell: (row) => (
				<div>
					{row.imageIds && (row.imageIds.length > 0) ?
						<img
							src={endPointGetImage(row.imageIds[0])}
							className="w-20 h-20 object-scale-down"
							alt={''}
							id={'img' + uuid()}
						/>
						: <></>
					}
				</div>
			),
			center: true,
			width: '180px',
		},
		{
			name: 'Name',
			selector: row => row.name,
			center: true,
			width: '180px'
		},
		{
			name: 'Hersteller',
			selector: row => row.manufacturer?.name ?? '',
			center: true,
			width: '180px'
		},
		{
			name: 'Varianten-Indikator',
			cell: (row) => (
				row.properties?.find(p => p.necessity === 'VARIATION') ?
					<div>
						<label>{row.properties?.find(p => p.necessity === 'VARIATION')?.name} {row.properties?.find(p => p.necessity === 'VARIATION')?.value}{row.properties?.find(p => p.necessity === 'VARIATION')?.unit}</label>
					</div> : ''

			),
			center: true,
			width: '180px'
		},

		{
			name: 'Preis',
			cell: (row) => (
				row.isSale && row.effectiveSale ?
					<div>
						<label className={'text-red-500 line-through'}>{row.price / 100}€</label>

						<label>{row.totalPrice ? row.totalPrice / 100 : ''}€</label>
					</div>
					:
					<div>
						<label>{row.price / 100}€</label>
					</div>

			),
			center: true,
			width: '180px'
		}
	];


	const categorySalesColums: TableColumn<CategorySale>[] = [
		{
			name: 'Kategorie-Name',
			selector: row => row.category?.name ?? '',
			center: true,
			width: '180px'
		},
		{
			name: 'Reduziert %',
			selector: row => row.percentage / 100,
			center: true,
			width: '130px'
		},
		{
			name: 'Enddatum',
			selector: row => row.endDate ? FormatDate(row.endDate) : '-',
			center: true,
			width: '180px'
		},
		{
			name: 'Partnerunternehmen',
			selector: row => row.buyer?.name ?? '-',
			center: true,
			width: '180px'
		},
	];


	return (
		<div className="w-full px-4 sm:px-6 lg:px-8 mt-8 flex flex-row">
			<div className="flex flex-col w-1/3">

				<ComboBox
					wrapperClasses={'w-7/12'}
					title={'Partnerunternehmen (optional)'}
					items={partnerCompanies.map(item => ({key: item.id, label: item.name, object: item}))}
					onChangeSelected={(company: Company) => company ? setPartnerCompanyId(company.id) : setPartnerCompanyId(undefined)}
				/>

				<CategoriesSelection
					wrapperClasses={'w-9/12 pt-4'}
					setSelectionValid={() => null}
					setDeepestCategoryId={setDeepestCategoryId}/>

				{saleExists && deepestCategoryId &&
					<div className={'text-red-600 pt-1'}>
						* Auf diese Kategorie besteht bereits ein Rabatt!
					</div>
				}
				{saleOnParentExists &&
					<div className={'text-red-600 pt-1'}>
						* Achtung, diese Kategorie ist eine Subkategorie von einer Kategorie, auf die bereits ein
						<br/>
						Rabatt besteht!
					</div>
				}

				<div className="mb-2 flex flex-row space-x-4 py-5">
					<Button
						disabled={saleExists || !deepestCategoryId}
						label="Weiter"
						icon={IconType.ARROW_LEFT}
						onClick={() => setIsCreateCategoryModalVisible(true)}/>
					<text
						className="self-center">
                        Aktuelle Produktanzahl in der Kategorietiefe: {totalAmount}
					</text>
				</div>

				{categorySales &&
					<DataTable
						columns={categorySalesColums}
						data={categorySales}
						noDataComponent={<div></div>}/>
				}
			</div>

			{variants.length > 0 ? (
				<div className="flex flex-col pl-10">
					<div className={'text-2xl self-center'}>
                        Betroffene Produkte
					</div>

					<DataTable
						columns={colums}
						data={variants}
						customStyles={customStyles}
						paginationTotalRows={totalAmount}
						progressPending={loading}/>

					{nextPageToken !== '@end' &&
						<Button
							wrapperClasses="py-5 self-center"
							type={ButtonType.BUTTON}
							label="load more"
							onClick={() => loadVariants(nextPageToken)}/>
					}
				</div>
			) : (
				<div className="ml-6">
					<h1 className="text-2xl font-medium	">Keine Produkte betroffen</h1>
					<h1 className="text-xl font-medium	">Wählen sie eine Kategorie</h1>
				</div>
			)}

			<CreateCategorySaleModal
				numProducts={totalAmount}
				saleExistsWarning={saleExists}
				isVisible={isCreateCategoryModalVisible}
				onClose={() => setIsCreateCategoryModalVisible(false)}
				onSuccess={(percentage, endDate) => onSuccesCreateCategorySale(percentage, endDate)}/>
		</div>
	);
}
