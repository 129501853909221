import React from 'react';
import { ButtonType } from './Button';
import classNames from 'classnames';

import { PhotoIcon } from '@heroicons/react/20/solid';
import {endPointGetImage, ENDPOINTS} from '../../constants/ApiEndpoints';
import uuid from 'react-uuid';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	onClick				:(event: React.MouseEvent<HTMLButtonElement>) => void
	imageId?			:string
	previewData?		:string
	previewContentType?	:string
	wrapperClasses?		:string
}

const ImageButton = ({ onClick, imageId, previewData, previewContentType, wrapperClasses }: Props) => {
	return (
		<div className={classNames(wrapperClasses)}>
			<button
				type={ButtonType.BUTTON}
				onClick={onClick}
				className="inline-flex items-center rounded-full border border-gray-300 bg-white px-1 py-1
			text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none
			focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
			>
				{imageId && imageId !== '' ?
					<img
						src={endPointGetImage(imageId)}
						className="object-scale-down w-20 h-20"
						alt={'image preview'}
						id={'img' + uuid()}
					/>
					: previewData && previewContentType ?
						<img
							className="object-scale-down w-20 h-20"
							src={'data:' + previewContentType + ';base64,' + previewData}
							alt={'preview'}
							width={200}
							height={200}
						/>

						:
						<PhotoIcon className="h-20 w-20 text-gray-500" aria-hidden="true" />
				}
			</button>
		</div>
	);
};

export default ImageButton;
