import React, {Fragment} from 'react';
import {Bars3BottomLeftIcon} from '@heroicons/react/24/outline';
import {HiOutlineUserCircle} from 'react-icons/hi';
import {Menu, Transition} from '@headlessui/react';
import {useAuth} from '../../../providers/auth/AuthProvider';

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

const TopBar = () => {
	const auth = useAuth();

	const onSignOutClick = () => {
		auth.logout();
	};

	const userNavigation = [
		/*  { name: 'Your Profile', href: '#' },
            { name: 'Settings', href: '#' },*/
		{ name: 'Ausloggen', onClick: onSignOutClick },
	];

	return (
		<div className="z-0 md:pl-5">
			<div className="mx-auto flex max-w-4xl flex-col md:px-8 xl:px-0">
				<div className="sticky top-0 z-10 flex h-16 flex-shrink-0 {/*border-b border-gray-200 */}bg-white">
					<button
						type="button"
						className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
						/*onClick={() => setSidebarOpen(true)}*/
					>
						<span className="sr-only">Open sidebar</span>
						<Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
					</button>
					<div className="flex flex-1 justify-between px-4 md:px-0">
						{/*						<div className="flex flex-1">
							<form className="flex w-full md:ml-0" action="src/components#" method="GET">
								<label htmlFor="search-field" className="sr-only">
								Suchen
								</label>
								<div className="relative w-full text-gray-400 focus-within:text-gray-600">
									<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
										<MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
									</div>
									<input
										id="search-field"
										className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
										placeholder="Suchen"
										type="search"
										name="search"
									/>
								</div>
							</form>
						</div>*/}
						<div className="ml-4 flex items-center md:ml-6">
							{/*							<button
								type="button"
								className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
							>
								<span className="sr-only">View notification</span>
								<BellIcon className="h-6 w-6" aria-hidden="true" />
							</button>*/}

							{/* Profile dropdown */}
							<Menu as="div" className="relative ml-3">
								<div>
									<Menu.Button className="flex max-w-xs items-center rounded-full text-sm
										focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
										<span className="sr-only">Open user menu</span>
										<HiOutlineUserCircle size="2em"/>
									</Menu.Button>
								</div>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md
										bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
										{userNavigation.map((item) => (
											<Menu.Item key={item.name}>
												{({ active }) => (
													<a
														onClick={item.onClick}
														className={classNames(
															active ? 'bg-gray-100' : '',
															'block py-2 px-4 text-sm text-gray-700',
														)}
													>
														{item.name}
													</a>
												)}
											</Menu.Item>
										))}
									</Menu.Items>
								</Transition>
							</Menu>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopBar;