import DataTable, {TableColumn} from 'react-data-table-component';
import {CategorySale} from '../../../interfaces';
import FormatDate from '../../../utils/date';
import React, {useEffect, useState} from 'react';
import {API} from '../../../api/API';
import Button, {ButtonType} from '../../../components/button/Button';
import MultiSearchBar, {SearchBarItem} from '../../../components/searchBar/MultiSearchBar';
import IconButton, {IconType} from '../../../components/button/IconButton';
import DeleteModal from '../../../components/modal/DeleteModal';
import {useErrorHandling} from '../../../providers/error/ErrorProvider';


const SearchFields: SearchBarItem[] = [
	{
		label: 'Name Kategorie',
		key: 'searchCategoryName'
	},
	{
		label: 'Name Partnerunternehmen',
		key: 'searchBuyerName'
	}
];

export default function CategorySaleOverview() {
	const errorHandling = useErrorHandling();

	const [nextPageToken, setNextPageToken] = useState<string>('');
	const [categorySales, setCategorySales] = useState<CategorySale[]>([]);
	const [searchQuery, setSearchQuery] = useState<{ [key: string]: string }>();
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
	const [idToDelete, setIdToDelete] = useState<string | undefined>();

	const loadCategorySales = async (token?: string) => {
		try {
			const resp = await API.CategorySale.list(
				{
					tokenStr: token,
					pageSize: 20,
					resolveBuyer: true,
					resolveCategory: true,
					...searchQuery
				}
			);
			const newCategorySales: CategorySale[] = resp.data['categorySales'];
			if (token && token !== '') {
				setCategorySales(categorySales.concat(newCategorySales));
			} else {
				setCategorySales(newCategorySales);
			}
			setNextPageToken(resp.data['nextPageToken']);
		} catch (e: any) {
			errorHandling(e);
		}
	};

	const onSuccessDeleteModal = async (id: string) => {
		try {
			await API.CategorySale.softDelete(id);
			setIdToDelete(undefined);
			setIsDeleteModalVisible(false);
		} catch (e: any) {
			errorHandling(e);
		}
	};

	useEffect(() => {
		if (!isDeleteModalVisible) {
			loadCategorySales().then();
		}
	}, [JSON.stringify(searchQuery), isDeleteModalVisible]);


	const colums: TableColumn<CategorySale>[] = [
		{
			name: 'Kategorie-Name',
			selector: row => row.category?.name ?? '',
			center: true,
			width: '180px'
		},
		{
			name: 'Reduziert %',
			selector: row => row.percentage / 100,
			center: true,
			width: '130px'
		},
		{
			name: 'Enddatum',
			selector: row => row.endDate ? FormatDate(row.endDate) : '-',
			center: true,
			width: '180px'
		},
		{
			name: 'Partnerunternehmen',
			selector: row => row.buyer?.name ?? '-',
			center: true,
			width: '180px'
		},
		{
			name: '',
			right: true,
			grow: 1,
			cell: (row) =>
				<IconButton
					onClick={() => {
						setIsDeleteModalVisible(true);
						setIdToDelete(row.id);
					}}
					icon={IconType.TRASH}
					title='Produkt löschen'/>
		},
	];

	return (
		<div className={'pt-10 pb-3 w-1/2 px-10'}>
			<MultiSearchBar
				searchFields={SearchFields}
				onChange={setSearchQuery}/>

			<DataTable
				noDataComponent={<div className={'pt-20'}>Keine Treffer vorhanden</div>}
				columns={colums}
				data={categorySales}/>

			{nextPageToken !== '@end' &&
				<Button
					wrapperClasses="py-5 self-center"
					type={ButtonType.BUTTON}
					label="load more"
					onClick={() => loadCategorySales(nextPageToken).then()}/>
			}

			<DeleteModal
				isVisible={isDeleteModalVisible}
				onClose={() => {
					setIdToDelete(undefined);
					setIsDeleteModalVisible(false);
				}}
				onSuccess={() => onSuccessDeleteModal(idToDelete ?? '').then()}
				nameObjectToDelete={'Variante'}/>
		</div>
	);
}