import DataTable, {SortOrder, TableColumn} from 'react-data-table-component';
import {Company} from '../../interfaces';
import React, {useEffect, useState} from 'react';
import {Constants} from '../../constants';
import Button, {ButtonType} from '../../components/button/Button';
import IconButton, {IconType} from '../../components/button/IconButton';
import {NotificationType, useNotifications} from '../../providers/notification/NotificationProvider';
import SearchBar from '../../components/searchBar/SearchBar';
import {API} from '../../api';
import {useErrorHandling} from '../../providers/error/ErrorProvider';

export default function PartnershipSelection() {
	const errorHandling = useErrorHandling();
	const notifications = useNotifications();

	const [companies, setCompanies] = useState<Company[]>([]);
	const [nextPageToken, setNextPageToken] = useState<string>('');
	const [sortDirection, setSortDirection] = useState<string>(Constants.SortDirection.SortASC);
	const [searchAfterName, setSearchAfterName] = useState<string>('');


	useEffect(() => {
		loadPossibleSellerCompanies('').then();
	}, [searchAfterName]);


	async function requestPartnership(sellerId: string, sellerName: string) {
		try {
			await API.CompanyPartnership.request(sellerId);

			const message = 'Anfrage zur Partnerschaft mit dem Unternehmen ' + sellerName + ' gestellt.';

			notifications.push(NotificationType.success, 'Anfrage Partnerschaft', message);

			const indexToDelete: number = companies.findIndex(elem => elem.id === sellerId);
			if (indexToDelete >= 0) {
				const newCompanies: Company[] = companies;
				newCompanies.splice(indexToDelete, 1);
				setCompanies(newCompanies);
			}
		} catch (e: any) {
			errorHandling(e);
		}
	}


	async function loadPossibleSellerCompanies(token: string) {
		try {
			const resp = await API.Company.listSellerCompanies(
				{
					isSellingToUs: false,
					searchName: searchAfterName !== '' ? searchAfterName : undefined,
					token: token,
					pageSize: 15,
					sortPrimary: 'name',
					sortDirectionPrimary: sortDirection,
				},
			);

			const fetchedCompanies: Company[] = resp.data['companies'];
			if (token !== '') {
				setCompanies(companies.concat(fetchedCompanies));
			} else {
				setCompanies(fetchedCompanies);
			}
			setNextPageToken(resp.data['nextPageToken']);

		} catch (e: any) {
			errorHandling(e);
		}
	}


	const onSortChange = async (selectedColumn: TableColumn<Company>, sortDirection: SortOrder, sortedRows: any[]) => {
		if (sortDirection === 'asc') {
			setSortDirection(Constants.SortDirection.SortASC);
		} else if (sortDirection == 'desc') {
			setSortDirection(Constants.SortDirection.SortDESC);
		}
		loadPossibleSellerCompanies('').then();
	};


	const colums: TableColumn<Company>[] = [
		{
			name: 'Name',
			selector: row => row.name,
			center: true,
			sortable: true,
			width: '250px'
		},
		{
			name: 'Anfrage',
			right: true,
			grow: 1,
			cell: (row, rowIndex, column) =>
				<div className="flex items-center gap-2 pr-1">
					<IconButton
						onClick={() => requestPartnership(row.id, row.name).then()}
						icon={IconType.PLUS}
						title='Partnerschaft anfragen'/>
				</div>
		},
	];

	return (
		<div>
			<SearchBar
				wrapperClasses={'w-1/3 pt-12 px-12'}
				results={companies}
				searchStr={searchAfterName}
				setSearchStr={setSearchAfterName}
				onClickResult={(id: string, name: string) => requestPartnership(id, name).then()}/>

			<div className={'w-1/3 px-12 flex flex-col'}>
				<DataTable
					columns={colums}
					data={companies}
					defaultSortFieldId={1}
					defaultSortAsc={true}
					sortServer
					onSort={onSortChange}/>

				{nextPageToken !== '@end' &&
					<Button
						wrapperClasses="py-5 self-center"
						type={ButtonType.BUTTON}
						label="load more"
						onClick={() => loadPossibleSellerCompanies(nextPageToken).then()}/>
				}
			</div>
		</div>
	);
}
