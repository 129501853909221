export const ENDPOINTS = {
	login: 'auth/web/login',
	register: 'auth/companies/register',
	inviteUser: 'auth/invite',
	finishAppRegistration: 'auth/invite/finish',
	finishCompanyRegistration: 'auth/companies/mailVerification',
	getUser: 'user',
	setOwnActiveStateUser: 'user/own/active',
	passwordReset: 'password/reset',
	passwordResetInfo:'password/reset/info/',
	passwordUpdate:'password/update/',
	products:'products/',
	listProducts:'products',
	listCompanyProducts:'products/fromCompany',
	categories:'categories',
	listAllCategories: 'categories/all',
	getCategory:'categories/',
	manufacturers: 'manufacturers',
	machines: 'machines',
	companies: 'companies',
	sellerCompanies: 'companies/seller',
	ownCompany: 'companies/own',
	updateUserCompany: 'companies',
	listOrderBundles: 'orderBundles',
	getOrderBundle: 'orderBundles/',
	listOrders: 'orders',
	getOrder: 'orders/',
	listFilters: 'filters',
	getFilter: 'filters/:fid',
	listPermissionGroups: 'permissionGroups',
	createProductWithVariants: 'products/withVariants',
	getFiles:'files',
	addFilesToProduct: 'products/:productId/file/add',
	listVariants: 'variants/list',
	listCompanyVariants: 'variants/list/fromCompany',
	companyPartnerships: 'companyPartnerships',
	listCompanyPartnershipsAsSeller: 'companyPartnerships/seller',
	listCompanyPartnershipsAsBuyer: 'companyPartnerships/buyer',

	createSaleOnVariants: 'sales/variants'
};


export function endPointListCompanyUsers(companyId:string):string{
	return 'companies/' + companyId + '/users';
}

export function endPointUserUnblock(userId:string):string{
	return 'user/' + userId + '/unblock';
}

export function endPointUserBlock(userId:string):string{
	return 'user/' + userId + '/block';
}

export function  endPointUserSetActiveState(userId:string):string {
	return 'user/' + userId + '/active';
}

export function endPointVariantSoftDelete(productId:string, variantId:string):string {
	return 'products/' + productId + '/variants/' + variantId + '/delete';
}

export function endPointGetImage(imageId:string):string{
	return process.env.REACT_APP_API_URL + '/image/' + imageId;
}

export function endPointProduct(productId:string):string {
	return 'products/' + productId;
}

export function endPointFilter(filterId:string):string {
	return 'filters/' + filterId;
}
