import {NotificationType, useNotifications} from '../../providers/notification/NotificationProvider';
import React, {useEffect, useState} from 'react';
import {Constants} from '../../constants';
import {CompanyPartnership, CompanyPartnershipStatus} from '../../interfaces/companyPartnership';
import DataTable, {SortOrder, TableColumn} from 'react-data-table-component';
import IconButton, {IconType} from '../../components/button/IconButton';
import SearchBar from '../../components/searchBar/SearchBar';
import Button, {ButtonType} from '../../components/button/Button';
import {API} from '../../api';
import {useErrorHandling} from '../../providers/error/ErrorProvider';

export default function PartnershipsAsSeller() {

	const errorHandling = useErrorHandling();
	const notifications = useNotifications();
	const [nextPageToken, setNextPageToken] = useState<string>('');
	const [sortDirectionName, setSortDirectionName] = useState<string>(Constants.SortDirection.SortASC);
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchResults, setSearchResults] = useState<CompanyPartnership[]>([]);
	const [partnerships, setPartnerships] = useState<CompanyPartnership[]>([]);

	
	useEffect(() => {
		loadPartnershipsAsSeller(sortDirectionName, '').then();
	}, []);


	async function loadPartnershipsAsSeller(sortDirection: string, token: string) {
		try {
			const resp = await API.CompanyPartnership.listAsSeller(
				{
					status: CompanyPartnershipStatus.active,
					token: token,
					pageSize: 15,
					sortPrimary: 'buyer.name',
					sortDirectionPrimary: sortDirection,
				}
			);

			const fetchedPartnerships: CompanyPartnership[] = resp.data['companyPartnerships'];
			if (token !== '') {
				setPartnerships(partnerships.concat(fetchedPartnerships));
			} else {
				setPartnerships(structuredClone(fetchedPartnerships));
			}
			setNextPageToken(resp.data['nextPageToken']);

		} catch (e: any) {
			errorHandling(e);
		}
	}


	async function deletePartnerShip(partnershipId: string, buyerName: string) {
		try {
			await API.CompanyPartnership.delete(partnershipId);

			const message = 'Partnerschaft zu Unternehmen ' + buyerName + ' wurde geloescht.';
			notifications.push(NotificationType.success, 'Partnerschaft geloescht', message);

			const indexToDelete: number = partnerships.findIndex((elem) => elem.id === partnershipId);
			if (indexToDelete >= 0) {
				const newPartnerships: CompanyPartnership[] = partnerships;
				newPartnerships.splice(indexToDelete, 1);
				setPartnerships(newPartnerships);
			}
		} catch (e: any) {
			errorHandling(e);
		}
	}


	useEffect(() => {
		if (searchValue !== '') {
			search().then();
		} else {
			setSearchResults([]);
		}
	}, [searchValue]);


	async function search() {
		try {
			const resp = await API.CompanyPartnership.listAsSeller(
				{
					searchBuyerName: searchValue,
					status: CompanyPartnershipStatus.active,
					pageSize: 10
				}
			);

			const results: CompanyPartnership[] = resp.data['companyPartnerships'];
			if (results) {
				for (let i = 0; i < results.length; i++) {
					results[i].name = results[i].buyer?.name ?? '';
				}
			}
			setSearchResults(results);

		} catch (e: any) {
			errorHandling(e);
		}
	}


	const onSortChange = async (
		selectedColumn: TableColumn<CompanyPartnership>,
		sortDirection: SortOrder,
		sortedRows: any[]
	) => {
		if (sortDirection === 'asc') {
			setSortDirectionName(Constants.SortDirection.SortASC);
			loadPartnershipsAsSeller(Constants.SortDirection.SortASC, '').then();
		} else if (sortDirection == 'desc') {
			setSortDirectionName(Constants.SortDirection.SortDESC);
			loadPartnershipsAsSeller(Constants.SortDirection.SortDESC, '').then();
		}
	};


	const colums: TableColumn<CompanyPartnership>[] = [
		{
			name: 'Name',
			selector: (row) => row.buyer?.name ?? '',
			center: true,
			sortable: true,
			width: '250px',
		},
		{
			name: 'Aktion',
			right: true,
			grow: 1,
			cell: (row, rowIndex, column) => (
				<div className="flex items-center gap-2 pr-1">
					<IconButton
						onClick={() => deletePartnerShip(row.id, row.buyer?.name ?? '').then()}
						icon={IconType.TRASH}
						title="Partnerschaft loeschen"/>
				</div>
			),
		},
	];


	return (
		<div>
			<SearchBar
				wrapperClasses={'w-1/3 pt-12 px-12'}
				results={searchResults}
				searchStr={searchValue}
				setSearchStr={setSearchValue}
				onClickResult={(partnershipId: string, name: string) => console.log('TODO')}/>

			<div className={'w-1/3 px-12 flex flex-col'}>
				<DataTable
					columns={colums}
					data={partnerships}
					defaultSortFieldId={1}
					defaultSortAsc={true}
					sortServer
					onSort={onSortChange}/>

				{nextPageToken !== '@end' && (
					<Button
						wrapperClasses="py-5 self-center"
						type={ButtonType.BUTTON}
						label="load more"
						onClick={() =>
							loadPartnershipsAsSeller(sortDirectionName, nextPageToken).then()
						}
					/>
				)}
			</div>
		</div>
	);
}