import React, {createContext, useContext} from 'react';
import {NotificationType, useNotifications} from '../notification/NotificationProvider';
import axios, {AxiosError} from 'axios';
import {APIError, ErrorIdDE, ErrorMessagesDE} from '../../api';


type ErrorContextType = (e: Error) => void


const ErrorContext = createContext<ErrorContextType>(e => {
	throw e; // default function
});


export const useErrorHandling = () => {
	return useContext(ErrorContext);
};


const formatAxiosError = (error: AxiosError): APIError => {
	let body: APIError = {errorId: '', errorMessage: ''};

	if (error.response) {
		try {
			body = error.response.data as APIError;
		} catch (e) {
			console.log(e);
		}
	} else {
		return {errorId: ErrorIdDE['NETWORK'], errorMessage: ErrorMessagesDE['NETWORK']};
	}

	return {errorId: ErrorIdDE[body.errorId], errorMessage: ErrorMessagesDE[body.errorId]};
};


interface Props {
    children: React.ReactNode
}


export const ErrorProvider: React.FC<Props> = (props: Props) => {
	const notifications = useNotifications();

	const axiosHandler = (error: AxiosError) => {
		const apiError: APIError = formatAxiosError(error);
		console.log('error', apiError.errorId);
		notifications.push(NotificationType.failure, apiError.errorId, apiError.errorMessage);
	};

	const handler = (e: Error) => {
		if (axios.isAxiosError(e)) {
			axiosHandler(e);
		} else {
			throw e;
		}
	};

	return (
		<ErrorContext.Provider value={handler}>
			{props.children}
		</ErrorContext.Provider>
	);
};