import React, {ChangeEventHandler, FC} from 'react';
import classNames from 'classnames';

interface Props{
    title:string
    onChange: ChangeEventHandler<HTMLTextAreaElement>
    value: string
	rows?:number
    wrapperClasses?: string;
}


const TextArea: FC<Props> = (props) => {
	return (
		<div className={
			classNames('flex flex-col grow', props.wrapperClasses)
		}>
			<label className="block text-sm font-medium text-gray-700"> {props.title} </label>
			<textarea {...props} className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
			placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"/>
		</div>
	);
};

export default TextArea;