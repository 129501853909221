import {User} from '../../../interfaces';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../providers/auth/AuthProvider';
import ComboBox from '../../../components/comboBox/ComboBox';
import {useErrorHandling} from '../../../providers/error/ErrorProvider';
import {API} from '../../../api';


interface Props {
	targetUserId?:string
	selectedSupervisor?: User | undefined
	setSelectedSupervisor?: React.Dispatch<React.SetStateAction<User | undefined>>
	wrapperClasses?:string
}

export default function SupervisorSelection(
	{
		targetUserId,
		selectedSupervisor, setSelectedSupervisor,
		wrapperClasses
	}:Props) {

	const auth = useAuth();
	const errorHandling = useErrorHandling();

	const [companyUsers, setCompanyUsers] = useState<User[]>([]);


	useEffect(() => {
		loadCompanyUsers().then();
	}, []);


	async function ListSubordinates(targetUserId:string):Promise<any> {
		try {
			return await API.User.listSubordinates(targetUserId);
		} catch (e: any) {
			errorHandling(e);
		}
	}


	const loadCompanyUsers = async () => {
		try {
			const res = await API.Company.listUsers(auth.user?.company.id ?? '', {});

			let users: User[] = res.data['users'];

			for (let i = 0; i < users.length; i++) {
				users[i].name = users[i].firstName + ' ' + users[i].lastName;
			}

			if (targetUserId) {
				users = users.filter(elem => elem.id !== targetUserId);

				const subordinates: User[] = await ListSubordinates(targetUserId);


				if (subordinates) {
					for (let i = 0; i < subordinates.length; i++) {
						const indexToDelete: number = users.findIndex(elem => elem.id === subordinates[i].id);
						if (indexToDelete >= 0) {
							users.splice(indexToDelete, 1);
						}
					}
				}
			}

			setCompanyUsers(structuredClone(users));
		} catch (e: any) {
			errorHandling(e);
		}
	};

	
	return(
		<div className={classNames(wrapperClasses)}>
			<ComboBox
				title={'Vorgesetzer'}
				items={companyUsers.map(object => ({
					key: object.id,
					label: object.name + ' (' + object.mail + ')',
					object: object
				}))}
				onChangeInput={(val: string) => {
					if(val === '' && setSelectedSupervisor){
						setSelectedSupervisor(undefined);
					}
				}}
				onChangeSelected={(item) => (setSelectedSupervisor ? setSelectedSupervisor(item) : null)}/>
		</div>
	);
}