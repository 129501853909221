import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import IconButton, {IconType} from '../../../../../../../components/button/IconButton';
import {ButtonType} from '../../../../../../../components/button/Button';
import {FileInterface} from '../../../../../../../interfaces';


interface Props {
	files      		: FileInterface[]
	setFiles   		: React.Dispatch<React.SetStateAction<FileInterface[]>>
	fileType		: string
	accept			: string
    wrapperClasses?	: string
}

function FileSelectionList({files, setFiles, fileType, accept, wrapperClasses} : Props){
	const fileRef = React.useRef<HTMLInputElement>(null);
	const [newFile, setNewFile] = useState<File | null>(null);


	useEffect(() => {
		if (newFile) {
			const reader = new FileReader();
			reader.onload = () => {
				const readerArr = (reader.result as string).split(',');
				if (readerArr.length == 2){
					const v = {id:'', name:newFile.name, fileType:fileType, contentType:newFile.type, data:readerArr[1]};
					setFiles(oldArr => [...oldArr, v]);
					setNewFile(null);
				}
			};
			reader.readAsDataURL(newFile);
		}
	}, [newFile]);


	function DeleteFileFromArray(index:number) {
		let newArr: FileInterface[] = [];
		newArr = files.flat(1);
		newArr.splice(index, 1);
		setFiles(newArr);
	}

	return(
		<div className={classNames(wrapperClasses)}>

			<div className="flex flex-col py-2 px-4 gap-2 border rounded-lg">

				{files && files.map((file, index) => (
					<div className="p-2 border rounded-lg hover:border-blue-700 relative group">

						{file.name}

						<IconButton
							wrapperClasses="absolute top-1.5 left-15 right-2 hidden group-hover:block"
							icon={IconType.TRASH}
							onClick={()=>DeleteFileFromArray(index)}
						/>
					</div>
				))}

				<input
					type="file"
					onChange={(e)=>setNewFile(e.target.files?e.target.files[0]:null)}
					style={{display:'none'}}
					ref={fileRef}
					accept={accept}
				/>

				<IconButton
					wrapperClasses="self-center"
					icon={IconType.PLUS}
					onClick={()=>fileRef.current?.click()}
					type={ButtonType.BUTTON}
				/>
			</div>
		</div>
	);
}

export default FileSelectionList;
